import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import Container from '../common/components/Container';
import HeaderContainer from '../common/containers/headerContainer';
import DetailsContainer from '../modules/parts/container/detailsContainer';
import { populatePartState } from '../modules/parts/actions/parts';
import PartServices from '../modules/parts/services/parts';
import config from '../core/config/config.json';
import Loader from '../common/components/Loader';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

class PartPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    checkTokenIsStillValid();

    this.state = {
      loading: true,
      error: false,
    };
  }

  componentWillMount() {
    const id = Number(this.props.match.params.itemId);
    PartServices.findSpecificPart(id)
      .then(part => {
        if (part.image === null || typeof part.image === 'undefined') {
          part.image = config.missing_image;
        }
        const cart = cloneDeep(this.props.cart);
        const foundInCart = find(cart.cartitem_set, { spare_part: { id: part.id } });

        this.props.populatePartState(part, foundInCart);
        this.setState({ loading: false, error: false });
      })
      .catch(() => {
        this.setState({ error: true, loading: false });
      });
  }

  render() {
    return (
      <React.Fragment>
        <HeaderContainer search={this.props.location.search} history={this.props.history} />
        <Container margin="0 auto" alignItems="flex-start">
          {this.state.loading ? <Loader /> : ''}
          {this.state.loading === false && this.state.error === false ? <DetailsContainer /> : ''}
          {this.state.error === true ? (
            <div style={{ margin: '50px auto', opacity: '.5', fontSize: '18px' }}>
              No part with that ID found
            </div>
          ) : (
            ''
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({ cart: state.cart }),
  { populatePartState }
)(PartPage);
