import { combineReducers } from 'redux';

import intl from './intl';
import cart from '../../modules/cart/reducers/cart';
import parts from '../../modules/parts/reducers/parts';
import partsList from '../../modules/parts/reducers/partsList';
import adminDetails from '../../modules/admin/reducers/adminDetails';
import adminCreate from '../../modules/admin/reducers/adminCreate';
import login from '../../modules/login/reducers/login';

export default combineReducers({
  intl,
  cart,
  parts,
  partsList,
  adminDetails,
  adminCreate,
  login,
});
