import forOwn from 'lodash/forOwn';
import cloneDeep from 'lodash/cloneDeep';
import * as Constants from '../constants/constants';

const initialState = {
  toCart: {
    add_more: true,
    added_total: 0,
  },
  currency: '€',
  favorite: false,
  quantity: 0,
  in_stock: false,
};

const populateState = (state, part, foundInCart) => {
  const newState = cloneDeep(state);
  forOwn(part, (value, field) => {
    newState[field] = value;
  });
  newState.quantity = 1;
  newState.toCart = {
    add_more: true,
    added_total: foundInCart ? foundInCart.quantity : 0,
  };
  return newState;
};

// const fixPrice = value => {
//   return Number(value).toFixed(2);
// };

const updateParts = (state, field, value) => {
  const newState = cloneDeep(state);
  if (field === 'quantity') {
    newState.quantity = Number(value);
  } else if (field === 'toCart') {
    newState.toCart.add_more = false;
    newState.toCart.added_total = Number(newState.toCart.added_total) + Number(value);
  } else {
    newState[field] = value;
  }

  return newState;
};

const addMoreParts = state => {
  const newState = cloneDeep(state);
  newState.toCart.add_more = true;
  newState.quantity = 1;
  return newState;
};

const updateInitialAddedQuantity = (state, quantity) => {
  const newState = cloneDeep(state);
  newState.toCart.added_total = quantity;
  return newState;
};

const partsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.UPDATE_PARTS_STATE:
      return updateParts(state, action.field, action.value);
    case Constants.ADD_MORE_PARTS_TO_CART:
      return addMoreParts(state);
    case Constants.UPDATE_INITIAL_PART_ADDED_QUANTITY:
      return updateInitialAddedQuantity(state, action.quantity);
    case Constants.POPULATE_PARTS_STATE:
      return populateState(state, action.part, action.foundInCart);
    default:
      return state;
  }
};

export default partsReducer;
