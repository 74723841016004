import React from 'react';
import styled from 'styled-components';
import { RemoveCircleOutline, Launch } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ImgComponent from '../../../../common/components/ItemImg';
import ItemQuantity from '../../../../common/components/ItemQuantity';
import TotalPrice from '../../../../common/components/TotalPrice';
import ButtonRemove from '../../../../common/components/IconButton';
import GotoNew from '../../../../common/components/LargeButton';
import config from '../../../../core/config/config.json';

const ContainerItem = styled.div`
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;
const ContentDiv = styled.div`
  overflow: hidden;
  flex: 1 0 20%;

  @media (max-width: 400px) {
    margin-left: 80px;
  }
`;
const ContentPrice = styled.div`
  overflow: hidden;
  flex: 1 0 20%;
  text-align: right;

  @media (max-width: 400px) {
    display: flex;
    justify-content: space-between;
    flex: 1 0 30%;
  }
`;
const Div = styled.div`
  display: flex;
  flex: 1 0 60%;

  @media (max-width: 400px) {
    flex: 1 0 100%;
  }
`;
const ItemDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;

  @media (max-width: 400px) {
    margin-bottom: 0px;
  }
`;
const ItemTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #2d9cdb;
  margin: 0;
  a {
    color: #2d9cdb;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }
`;
const ItemNr = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: rgba(43, 61, 80, 0.8);
  margin: 5px 0 0 0;

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
const InStock = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #219653;
  margin: 10px 0 0 0;

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
const NotInStock = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: rgb(235, 87, 87);
  margin: 10px 0 0 0;

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
const ContentReplacement = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 40%;
  height: 67px;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 400px) {
    margin: 10px 0;
    justify-content: center;
  }
`;
const ReplacementText = styled.p`
  color: #eb5757;
  font-size: 16px;
  text-align: right;
  margin: 0;

  @media (max-width: 400px) {
    margin-bottom: 10px;
  }
`;
const DiscontinuedText = styled.p`
  color: #eb5757;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0 0 0;
`;
const iconStyles = {
  color: '#4c9bd6',
  fontSize: '14px',
  verticalAlign: 'middle',
  marginLeft: '10px',
};

const itemContainer = ({
  src,
  quantity,
  available,
  index,
  name,
  currency,
  hasUnderline,
  totalPrice,
  disableInput,
  itemNo,
  itemNoOldsystem,
  onQuantityChange,
  openRemoveModal,
  onModalChange,
  removeFromCart,
  id,
  cartID,
  unitsInStock,
  isInStock,
  replacement,
  history,
  showDeleteModal,
}) => {
  return (
    <ContainerItem>
      <Div>
        <ImgComponent src={src ? src : config.missing_image} placeHolderStyle="small" />
        <ItemDetails>
          <ItemTitle>
            <Link to={`/item/${itemNo}`}> {name}</Link>
          </ItemTitle>
          <ItemNr>{itemNoOldsystem ? `${itemNo} / ${itemNoOldsystem}` : itemNo}</ItemNr>
          {replacement ? (
            <DiscontinuedText>Discontinued</DiscontinuedText>
          ) : (
            <React.Fragment>
              {/* eslint-disable-next-line no-nested-ternary */}
              {isInStock ? (
                unitsInStock ? (
                  <InStock>{unitsInStock} in stock</InStock>
                ) : (
                  <InStock>In Stock</InStock>
                )
              ) : (
                <NotInStock>Not in stock</NotInStock>
              )}
            </React.Fragment>
          )}
        </ItemDetails>
      </Div>
      {replacement ? (
        <ContentReplacement>
          <ReplacementText>{`This article was replaced with Article No. ${replacement}`}</ReplacementText>
          <GotoNew
            color="#4c9bd6"
            value="New article page"
            background="none"
            padding="0px"
            border="none"
            width="fit-content"
            onSubmit={() => history.push(`/item/${replacement}`)}
          >
            <Launch style={iconStyles} />
          </GotoNew>
        </ContentReplacement>
      ) : (
        <React.Fragment>
          <ContentDiv>
            <ItemQuantity
              noUnderline={hasUnderline}
              disableInput={disableInput}
              handleOnChange={e =>
                onQuantityChange ? onQuantityChange(index, e.target.value, id) : null
              }
              value={quantity}
            />
          </ContentDiv>
          <ContentPrice>
            <TotalPrice available={available} value={totalPrice} currency={currency} />
            {removeFromCart ? (
              <ButtonRemove
                onClick={() => {
                  showDeleteModal();
                }}
                Icon={RemoveCircleOutline}
                iconColor="#EB5757"
                iconSize="12px"
                iconAlign="middle"
                iconMargin="10px"
                value="Remove"
                color="#EB5757"
                display="none"
                padding="0"
                background="transparent"
                border="none"
              />
            ) : (
              ''
            )}
          </ContentPrice>
        </React.Fragment>
      )}
    </ContainerItem>
  );
};

export default itemContainer;
