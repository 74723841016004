import React from 'react';
import styled from 'styled-components';
import Logo from '../../../common/components/Logo';
import SentEmailComponent from './forgottenPassElements/sentEmail';
import LoginService from '../services/login';
import Container from '../../../common/components/Container';
import { emailValidation } from '../../../core/validation/validation';

const buttonStyleActive = styled.button`
  color: #fff;
  width: 100%;
  background: #4c9bd6;
  border: 1px solid #478dc1;
  border-radius: 3px;
  padding: 10px;
`;
const Title = styled.h1`
  font-size: 18px;
  font-weight: 300;
  margin: 20px 0;
`;

export default class ForgotPasswordPage extends React.Component {
  constructor() {
    super();

    this.state = {
      email: '',
      error: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit() {
    if (emailValidation(this.state.email)) {
      LoginService.forgottenPassword(this.state.email)
        .then(() => {
          window.location.href = '/reset/success';
        })
        .catch(err => {
          this.setState({ error: err.response.body });
        });
    } else {
      this.setState({ error: 'This is not a valid e-mail adress.' });
    }
  }

  handleChange(e) {
    this.setState({
      email: e.target.value,
      error: null,
    });
  }

  render() {
    return (
      <Container maxWidth="450px" margin="auto">
        <Logo />
        <Title>Forgot password</Title>
        <SentEmailComponent
          error={this.state.error}
          onSubmit={this.handleSubmit}
          email={this.state.email}
          handleChange={this.handleChange}
          buttonStyleActive={buttonStyleActive}
        />
      </Container>
    );
  }
}
