import React from 'react';
import styled from 'styled-components';

const ContentDiv = styled.div`
  overflow: hidden;
  flex: 0 1 calc(33% - 10px);
  margin-right: 10px;

  @media (max-width: 400px) {
    flex: 0 1 calc(49% - 10px);
  }
`;

const ItemNoBox = ({ title, value, ElementNrLabel, ElementNrValue }) => (
  <ContentDiv>
    <ElementNrLabel>{title}</ElementNrLabel>
    <ElementNrValue>{value || '-'}</ElementNrValue>
  </ContentDiv>
);

export default ItemNoBox;
