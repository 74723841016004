import React from 'react';
import styled from 'styled-components';
import SearchInput from '../../../common/components/Input';
import SearchButton from '../../../common/components/LargeButton';
import { checkTokenIsStillValid } from '../../../core/userAuth/userAuth';
import PartsService from '../services/parts';

const buttonStyleActive = styled.button`
  color: #fff;
  width: 100%;
  background: #4c9bd6;
  border: 1px solid #478dc1;
  border-radius: 3px;
  padding: 10px;
`;
const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
const ErrorContainer = styled.div`
  text-align: left;
  width: 100%;
  margin-top: 10px;
`;
const ErrorMessage = styled.p`
  font-weight: 600;
  color: #eb5757;
  margin-bottom: 10px;
  margin: 0;
`;

export default class SearchComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      partNo: null,
      error: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onEnter = this.onEnter.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keypress', this.onEnter, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.onEnter, false);
  }

  onEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onSubmit();
    }
  }

  onChange(e) {
    this.setState({
      partNo: e.target.value,
      error: false,
    });
  }

  onSubmit() {
    checkTokenIsStillValid();

    PartsService.findSpecificPart(this.state.partNo)
      .then(part => {
        this.props.history.push(`/item/${part.item_no}`);
      })
      .catch(error => this.setState({ error }));
  }

  render() {
    return (
      <React.Fragment>
        <SearchContainer>
          <SearchInput
            blockWidth="80%"
            flexAlign="row nowrap"
            margin="0 10px 0 0"
            width="100%"
            border="1px solid rgba(43, 61, 80, 0.1);"
            value={this.state.partNo}
            placeholder="Input old/new article number"
            padding="10px 15px"
            radius="3px"
            name="part_search"
            handleOnChange={this.onChange}
          />
          <SearchButton
            ButtonElement={buttonStyleActive}
            width="20%;"
            value="Check"
            onSubmit={this.onSubmit}
          />
        </SearchContainer>
        <ErrorContainer>
          {this.state.error ? <ErrorMessage>No article found. Please try again.</ErrorMessage> : ''}
        </ErrorContainer>
      </React.Fragment>
    );
  }
}
