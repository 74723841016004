import React from 'react';
import styled from 'styled-components';

const SimpleButton = styled.button`
  width: ${props => (props.width ? props.width : '100%;')}
  margin: ${props => (props.margin ? props.margin : '0px 0px 0px 0px;')}
  font-family: 'Exo 2', sans-serif;
  cursor: pointer;
  color: #5d9ad1;
  background: none;
  border: none;
  padding: 10px;
  border-radius: 3px;
  &:hover {
    background: rgba(45, 156, 219, 0.1);
  }
`;

const SmallButton = ({ value, width, margin, onSubmit }) => (
  <React.Fragment>
    <SimpleButton onClick={onSubmit} type="submit" value={value} width={width} margin={margin}>
      {' '}
      {value}{' '}
    </SimpleButton>
  </React.Fragment>
);

export default SmallButton;
