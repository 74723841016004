import React from 'react';
import HeaderContainer from '../common/containers/headerContainer';
import FavoritePartsComponent from '../modules/parts/components/favoriteParts';
import PartsService from '../modules/parts/services/parts';
import MainLayout from '../layouts/mainLayout';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

export default class FavoritePartsPage extends React.Component {
  constructor() {
    super();

    checkTokenIsStillValid();

    this.state = {
      parts: [],
      loading: true,
      error: null,
    };
  }

  componentWillMount() {
    const self = this;
    PartsService.getFavoriteParts()
      .then(response => {
        self.setState({
          parts: response,
          loading: false,
        });
      })
      .catch(error => {
        self.setState({
          loading: false,
          error,
        });
      });
  }

  render() {
    return (
      <MainLayout background="#f2f2f2">
        <HeaderContainer tab="favourites" />
        <FavoritePartsComponent
          error={this.state.error}
          loading={this.state.loading}
          parts={this.state.parts}
        />
      </MainLayout>
    );
  }
}
