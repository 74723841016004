import React from 'react';
import QrReader from 'react-qr-reader';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { checkTokenIsStillValid } from '../../../core/userAuth/userAuth';
import Button from '../../../common/components/LargeButton';

const ContainerQrReader = styled.div`
  background: #333333;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  height: 100vh;
`;
const QrText = styled.p`
  color: #fff;
  max-width: 250px;
  text-align: center;
  font-size: 13px;
  margin-top: 30px;
`;

export default class ScanQRComponent extends React.Component {
  constructor() {
    super();

    checkTokenIsStillValid();

    this.state = {
      delay: 300,
      error: null,
      loading: false,
    };
    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  /**
   * @param {string} url Example: http://qrcode.desma.de/{itemNo}
   */
  handleScan(url) {
    if (url && this.state.loading === false) {
      const itemNo = new URL(url).pathname.replace('/', '');
      window.location.href = `/item/${itemNo}`;
    }
  }

  handleError(error) {
    if (this.state.loading === false) {
      this.setState({
        error,
        loading: true,
      });
      /** clear error after 3 sec */
      setTimeout(() => {
        this.setState({
          error: null,
          loading: false,
        });
      }, 3000);
    }
  }

  render() {
    return (
      <ContainerQrReader>
        {isMobile ? (
          <React.Fragment>
            <QrReader
              delay={this.state.delay}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '250px' }}
            />
            {this.state.error ? <QrText>No article found. Please try again.</QrText> : ''}
            <QrText>Position the QR-Code inside the red lines.</QrText>
            <Button
              value="Cancel"
              width="250px"
              color="#4c9bd6"
              border="none"
              background="#fff"
              margin="10px 0 0 0"
              onSubmit={() => {
                window.location.href = '/';
              }}
            />
          </React.Fragment>
        ) : (
          <p>You need to be on mobile to access this page content.</p>
        )}
      </ContainerQrReader>
    );
  }
}
