import React from 'react';
import HeaderContainer from '../common/containers/headerContainer';
import CartService from '../modules/cart/services/cart';
import CartListDetailsComponent from '../modules/cart/components/cartListDetails';
import Loader from '../common/components/Loader';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

class CartListPage extends React.Component {
  constructor(props) {
    super(props);

    checkTokenIsStillValid();

    this.state = {
      cart: {},
      loading: true,
      error: false,
    };
  }

  componentWillMount() {
    const self = this;
    CartService.retrieveCart(this.props.match.params.id)
      .then(cart => {
        self.setState({ cart, loading: false, error: false });
      })
      .catch(err => {
        self.setState({ error: true, loading: false });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div id="react-no-print">
          <HeaderContainer search={this.props.location.search} history={this.props.history} />
        </div>
        {this.state.loading ? <Loader /> : ''}
        {this.state.loading === false && this.state.error === false ? (
          <CartListDetailsComponent
            history={this.props.history}
            url={this.props.location.pathname}
            cart={this.state.cart}
          />
        ) : (
          ''
        )}
        {this.state.error === true ? (
          <div style={{ margin: '50px auto', opacity: '.5', fontSize: '18px' }}>No cart found</div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

export default CartListPage;
