import * as Constants from '../constants/constants';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  selected: 'lastOrders',
  lastOrders: [],
  lastViewed: [],
  favorites: [],
};

const updatePartsList = (state, field, value) => {
  let newState = cloneDeep(state);
  newState[field] = value;
  return newState;
};

const addToArray = (state, item, field) => {
  let newState = cloneDeep(state);
  newState[field].push(item);
  return newState;
};

const updateFavorites = (state, array) => {
  let newState = cloneDeep(state);
  newState.favorites = array;
  return newState;
};

const partsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.UPDATE_PARTS_LIST_STATE:
      return updatePartsList(state, action.field, action.value);
    case Constants.ADD_TO_PARTS_LIST_ARRAY:
      return addToArray(state, action.item, action.field);
    case Constants.UPDATE_FAVORITE_PARTS:
      return updateFavorites(state, action.array);
    default:
      return state;
  }
};

export default partsListReducer;
