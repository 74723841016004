import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import * as Constants from '../constants/constants';

const initialState = {
  id: null,
  name: null,
  saved: false,
  is_sent: false,
  openSaveModal: false,
  openRemoveModal: false,
  openEmptyModal: false,
  openSendCartModal: false,
  openSendModal: false,
  cartitem_set: [],
  invalidEmailError: false,
};

// const flatten = (arr, depth = 1) =>
//   depth !== 1
//     ? arr.reduce((a, v) => a.concat(Array.isArray(v) ? flatten(v, depth - 1) : v), [])
//     : arr.reduce((a, v) => a.concat(v), []);

const closeModals = state => {
  state.openSaveModal = false;
  state.openRemoveModal = false;
  state.openEmptyModal = false;
  state.openSendModal = false;
  return state;
};

const addItemToCart = (state, cart) => {
  let newState = cloneDeep(state);
  forOwn(cart, (value, field) => {
    newState[field] = value;
  });
  newState.saved = false;
  newState = closeModals(newState);
  return newState;
};

const removeItem = (state, cart) => {
  let newState = cloneDeep(state);
  newState.cartitem_set = cart.cartitem_set;
  newState.saved = false;
  newState = closeModals(newState);
  return newState;
};

const toggleModal = (state, field, value) => {
  const newState = cloneDeep(state);
  if (field === 'openSaveModal') {
    newState.name = null;
  }
  newState[field] = value;
  return newState;
};

const updateCartValue = (state, field, value) => {
  const newState = cloneDeep(state);
  newState.invalidEmailError = false;
  newState[field] = value;
  return newState;
};

const removeAllItems = state => {
  let newState = cloneDeep(state);
  newState.cartitem_set = [];
  newState.saved = false;
  newState.is_sent = false;
  newState = closeModals(newState);
  return newState;
};

const changeItemQuantity = (state, cart) => {
  const newState = cloneDeep(state);
  newState.cartitem_set = cart.cartitem_set;
  return newState;
};

const addFromSavedCarts = (state, cartArray) => {
  const newState = cloneDeep(state);
  let temp = cartArray[cartArray.length - 1];
  newState.cartitem_set = temp.cartitem_set;
  newState.id = temp.id;
  newState.is_sent = temp.is_sent;
  newState.name = temp.name;
  return newState;
};

const emailWithCartSent = state => {
  let newState = cloneDeep(state);
  newState.is_sent = true;
  newState = closeModals(newState);
  return newState;
};

const onEmailError = (state, error) => {
  let newState = cloneDeep(state);
  newState = closeModals(newState);
  return newState;
};

const updateCartAfterSave = (state, cart) => {
  let newState = cloneDeep(state);
  newState.openSaveModal = false;
  newState.id = cart.id;
  newState.cartitem_set = cart.cartitem_set;
  newState.saved = true;
  return newState;
};

const invalidEmailError = state => {
  let newState = cloneDeep(state);
  newState.invalidEmailError = true;
  return newState;
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.ADD_ITEM_TO_CART:
      return addItemToCart(state, action.cart);
    case Constants.REMOVE_ITEM_FROM_CART:
      return removeItem(state, action.cart);
    case Constants.TOGGLE_CART_MODAL:
      return toggleModal(state, action.field, action.value);
    case Constants.UPDATE_CART_VALUE:
      return updateCartValue(state, action.field, action.value);
    case Constants.REMOVE_ALL_ITEMS:
      return removeAllItems(state);
    case Constants.UPDATE_ITEM_QUANTITY:
      return changeItemQuantity(state, action.cart);
    case Constants.ADD_FROM_SAVED_CARTS:
      return addFromSavedCarts(state, action.cart);
    case Constants.SUCCESS_SENT_CART_EMAIL:
      return emailWithCartSent(state);
    case Constants.FAIL_SENT_CART_EMAIL:
      return onEmailError(state);
    case Constants.UPDATE_CART_VALUE_AFTER_SAVE:
      return updateCartAfterSave(state, action.cart);
    case Constants.INVALID_EMAIL_SENT_ON_CART:
      return invalidEmailError(state);
    default:
      return state;
  }
};

export default cartReducer;
