import React from 'react';
import BackToBtn from '../common/components/LargeButton';
import SuccessWithHeader from '../common/components/SuccessWithHeader';
import styled from 'styled-components';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

const TextTitle = styled.h3`
  color: rgba(43, 61, 80, 0.8);
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0;
  text-align: center;
  width: 90%;
`;
const TextBlock = styled.p`
  color: rgba(43, 61, 80, 0.8);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 40px;
  width: 90%;
`;

const buttonStyleActive = styled.button`
  color: #fff;
  width: 100%;
  background: #4c9bd6;
  border: 1px solid #478dc1;
  border-radius: 3px;
  padding: 10px;
`;

const SuccessSend = props => {
  checkTokenIsStillValid();

  return (
    <SuccessWithHeader tab="cart">
      <TextTitle>Your cart has been successfully sent.</TextTitle>
      <TextBlock>
        Your cart has been successfully sent to the e-mail adress you specified.
      </TextBlock>
      <BackToBtn
        ButtonElement={buttonStyleActive}
        onSubmit={() => props.history.push('/cart')}
        value="Back to current cart"
      />
    </SuccessWithHeader>
  );
};

export default SuccessSend;
