import React from 'react';
import styled from 'styled-components';

const ListItem = styled.span`
  cursor: pointer;
  color: rgba(43, 61, 80, 0.6);
  border-right: 1px solid rgba(43, 61, 80, 0.2);
  padding-right: 10px;
  margin-right: 10px;
  &:last-child {
    border: none;
  }
`;

const select = ({ array, selected, onSelect }) => (
  <React.Fragment>
    {array.map((item, key) => {
      return (
        <ListItem
          key={key}
          onClick={() => onSelect(item)}
          style={selected === item ? { color: '#2B3D50', fontWeight: '600' } : {}}
        >
          {item}
        </ListItem>
      );
    })}
  </React.Fragment>
);

export default select;
