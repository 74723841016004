import React from 'react';
import styled from 'styled-components';
import Logo from '../../../common/components/Logo';
import Input from '../../../common/components/Input';
import LoginButton from '../../../common/components/LargeButton';
import ForgotPasswordButton from '../../../common/components/SmallButton';
import Container from '../../../common/components/Container';
import LoginService from '../services/login';
import { setCookie } from '../../../core/cookie/cookie';

const ButtonsContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  height: 90px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
`;
const InputsContainer = styled.div`
  width: 100%;
  margin: 10px 0;
`;
const Title = styled.h1`
  font-size: 18px;
  font-weight: 300;
  margin: 20px 0;
`;
const ErrorContainer = styled.div`
  text-align: left;
  width: 100%;
`;
const ErrorText = styled.p`
  color: #eb5757;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0;
`;
const buttonStyleActive = styled.button`
  color: #fff;
  width: 100%;
  background: #4c9bd6;
  border: 1px solid #478dc1;
  border-radius: 3px;
  padding: 10px;
`;

export default class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      email: null,
      password: null,
      error: null,
      email_error: null,
      password_error: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleForgottenPassUrl = this.handleForgottenPassUrl.bind(this);
    this.onEnter = this.onEnter.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    const errField = `${name}_error`;

    this.setState({
      [name]: value,
      [errField]: null,
      error: null,
    });
  }

  componentDidMount() {
    document.addEventListener('keypress', this.onEnter, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.onEnter, false);
  }

  onEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.handleLoginSubmit();
    }
  }

  handleLoginSubmit() {
    const self = this;
    LoginService.Login(this.state.email, this.state.password)
      .then(response => {
        /** set cookie with user token */
        setCookie(response.headers['x-authorization-token']);
        /** set user email into localStorage */
        localStorage.setItem('email', response.body.email);
        window.location.href = '/';
      })
      .catch(error => self.handleError(error));
  }

  handleError = error => {
    this.setState({
      error,
      email_error: error,
      password_error: error,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  handleForgottenPassUrl() {
    // have to fix error
    window.location.href = '/forgotten-password';
  }

  render() {
    return (
      <Container maxWidth="450px" margin="auto">
        <Logo />
        <Title>Login</Title>
        <ErrorContainer>
          <ErrorText>
            {this.state.error ? 'The login credentials you provided are not correct.' : ''}
          </ErrorText>
        </ErrorContainer>
        <InputsContainer>
          <Input
            width="100%"
            error={this.state.email_error}
            label="Username/E-mail"
            placeholder="Input username or e-mail"
            value={this.state.email}
            name="email"
            handleOnChange={this.handleChange}
          />
        </InputsContainer>
        <InputsContainer>
          <Input
            width="100%"
            type="password"
            error={this.state.password_error}
            placeholder="Input password here"
            label="Password"
            value={this.state.password}
            name="password"
            handleOnChange={this.handleChange}
          />
        </InputsContainer>
        <ButtonsContainer>
          <LoginButton
            value="Login"
            ButtonElement={buttonStyleActive}
            onSubmit={this.handleLoginSubmit}
          />
          <ForgotPasswordButton value="Forgot password" onSubmit={this.handleForgottenPassUrl} />
        </ButtonsContainer>
      </Container>
    );
  }
}
