import React from 'react';
import { Check } from '@material-ui/icons';
import HeaderContainer from '../containers/headerContainer';
import Container from './Container';

const IconStyles = {
  margin: '20px 0',
  fontSize: '82px',
  color: '#219653',
};

const SuccessWithHeader = ({ children, tab }) => (
  <React.Fragment>
    <HeaderContainer tab={tab} />
    <Container maxWidth="600px">
      <Check style={IconStyles} />
      {children}
    </Container>
  </React.Fragment>
);

export default SuccessWithHeader;
