import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ToLoginButton from '../common/components/LargeButton';
import { Check } from '@material-ui/icons';
import SuccessWithoutHeader from '../common/components/SuccessWithoutHeader';

const Text = styled.p`
  color: #219653;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;
`;
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  & * {
    width: 100%;
  }
`;
const buttonStyleActive = styled.button`
  color: #fff;
  width: 100%;
  background: #4c9bd6;
  border: 1px solid #478dc1;
  border-radius: 3px;
  padding: 10px;
`;
const Title = styled.h1`
  font-size: 18px;
  font-weight: 300;
  margin: 20px 0;
`;
const IconStyles = {
  margin: '20px 0',
  fontSize: '82px',
  color: '#219653',
};

const ResetSuccess = () => (
  <SuccessWithoutHeader>
    <Title>Forgot password</Title>
    <Check style={IconStyles} />
    <Text>
      If the e-mail you entered exists in our system, we will send you a link for resetting your
      password. Please also check your spam folder.
    </Text>
    <ButtonsContainer>
      <Link to="/login">
        <ToLoginButton value="Back to login" ButtonElement={buttonStyleActive} />
      </Link>
    </ButtonsContainer>
  </SuccessWithoutHeader>
);

export default ResetSuccess;
