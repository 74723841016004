import React from 'react';
import styled from 'styled-components';

const AvailabilityBlock = styled.div`
  opacity: ${props => (props.alpha ? props.alpha : '')};
`;
const Label = styled.p`
  color: #000000;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
`;
const OnRequest = styled.p`
  color: #f2994a;
  font-size: 15px;
`;
const InStock = styled(OnRequest)`
  color: #219653;
`;
const NotInStock = styled(OnRequest)`
  color: rgb(235, 87, 87);
`;

const element = (isInStock, value, replacement) => {
  if (replacement) {
    return '-';
  }

  if (!isInStock) {
    return <NotInStock>Not in stock</NotInStock>;
  }

  if (isInStock) {
    if (value) {
      return <InStock>{`${value} in stock`}</InStock>;
    }

    return <InStock>In stock</InStock>;
  }

  return null;
};

const Availability = ({ value, alpha, replacement, isInStock }) => (
  <AvailabilityBlock alpha={alpha}>
    <Label> Availability </Label>
    {element(isInStock, value, replacement)}
  </AvailabilityBlock>
);

export default Availability;
