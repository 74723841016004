import React from 'react';
import styled from 'styled-components';
import PartsListElement from './partsListElements/partsListElement';
import CartListElement from './partsListElements/cartListElement';

const ContainerLists = styled.div`
  margin: 20px 0;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: center;
`;
const ListItem = styled.span`
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  color: rgba(43, 61, 80, 0.6);
  border-right: 1px solid rgba(43, 61, 80, 0.2);
  padding-right: 10px;
  margin-right: 10px;
  &:last-child {
    border: none;
  }
`;
const ErrorText = styled.p`
  color: #db3832;
`;

const PartsListComponent = ({ error, selected, onSelectChange, list }) => (
  <React.Fragment>
    {error ? (
      <ErrorText>
        There was an error loading this page. Please try again in a few minutes. If the problem
        persists please contact us at <b>desparts@desma.de</b>
      </ErrorText>
    ) : (
      <React.Fragment>
        <ContainerLists>
          <ListItem
            onClick={() => onSelectChange('sentCarts')}
            style={selected === 'sentCarts' ? { color: '#2B3D50', fontWeight: '700' } : {}}
          >
            Cart requests
          </ListItem>
          <ListItem
            onClick={() => onSelectChange('lastViewed')}
            style={selected === 'lastViewed' ? { color: '#2B3D50', fontWeight: '700' } : {}}
          >
            Last viewed
          </ListItem>
          <ListItem
            onClick={() => onSelectChange('favorites')}
            style={selected === 'favorites' ? { color: '#2B3D50', fontWeight: '700' } : {}}
          >
            Favorites
          </ListItem>
        </ContainerLists>
        {selected === 'sentCarts' ? (
          <CartListElement list={list} />
        ) : (
          <PartsListElement list={list} />
        )}
      </React.Fragment>
    )}
  </React.Fragment>
);

export default PartsListComponent;
