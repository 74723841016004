import React from 'react';
import styled from 'styled-components';

const ButtonElement = styled.button`
  font-family: 'Exo 2', sans-serif;
  color: ${props => (props.color ? props.color : '#fff')};
  width: ${props => (props.width ? props.width : '100%')};
  background: ${props => (props.background ? props.background : '#2D9CDB')};
  border: ${props => (props.border ? props.border : '1px solid #478dc1')};
  margin: ${props => (props.margin ? props.margin : '0px')};
  height: ${props => (props.height ? props.height : 'auto')};
  cursor: pointer;
  border-radius: 3px;
  outline: none;
  padding: ${props => (props.padding ? props.padding : '10px')};
`;

const LargeButton = ({
  value,
  color,
  background,
  border,
  width,
  height,
  padding,
  children,
  margin,
  onSubmit,
}) => (
  <React.Fragment>
    <ButtonElement
      onClick={onSubmit}
      type="submit"
      color={color}
      background={background}
      border={border}
      value={value}
      width={width}
      padding={padding}
      margin={margin}
      height={height}
    >
      {value}
      {children}
    </ButtonElement>
  </React.Fragment>
);

export default LargeButton;
