const containsSpecialCharacter = value => {
  const re = new RegExp(/[!@#$%^&*(){}[\]<>?/|.:;_-]/);
  return re.test(value);
};

const containsNumber = value => {
  const re = new RegExp(/[0-9]/);
  return re.test(value);
};

const containsUpperCase = value => {
  const re = new RegExp(/[A-Z]/);
  return re.test(value);
};

const containsLowerCase = value => {
  const re = new RegExp(/[a-z]/);
  return re.test(value);
};

const isPasswordValid = value => {
  const special = containsSpecialCharacter(value);
  const number = containsNumber(value);
  const upperCase = containsUpperCase(value);
  const lowerCase = containsLowerCase(value);
  const longEnough = value.length >= 10;

  return special && number && upperCase && lowerCase && longEnough;
};

export const validateResetPassword = async (password, passwordRepeat) => {
  const error = {
    msg: 'The entered passwords do not match.',
    msgTwo: 'Your entered password is not safe. Please follow the security instructions above.',
  };

  if (password !== passwordRepeat) {
    throw error.msg;
  }

  if (isPasswordValid(password) === false) {
    throw error.msgTwo;
  }

  return true;
};

export default { validateResetPassword };
