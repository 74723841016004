import { connect } from 'react-redux';
import AdminCreateComponent from '../components/adminCreate';
import { adminUpdate, submitAdminCreate } from '../actions/adminCreate';

const mapStateToProps = (state, props) => {
  return {
    details: state.adminCreate,
    history: props.history,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOnChange: e => {
      dispatch(adminUpdate(e.target.name, e.target.value));
    },
    onSelectChange: e => {
      dispatch(adminUpdate('role', e.target.value));
    },
    onSubmit: (data, history) => {
      dispatch(submitAdminCreate(data, history));
    },
  };
};

const AdminCreateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCreateComponent);

export default AdminCreateContainer;
