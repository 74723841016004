import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import NavigationPage from './NavigationPage';
import NotFound from '../core/components/NotFound';
import GlobalStyle from '../core/styles/styles';

function ApplicationPage({ locale }) {
  return (
    <div className="flex flex-column vh-100">
      <GlobalStyle />
      <Helmet
        htmlAttributes={{ lang: locale }}
        defaultTitle="Spare parts"
        titleTemplate=" | Spare parts"
      />
      <Switch>
        <Route path="/" component={NavigationPage} />
        <Route render={({ location }) => <NotFound pathname={location.pathname} />} />
      </Switch>
    </div>
  );
}

export default connect(state => state.intl)(ApplicationPage);
