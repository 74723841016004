import React from 'react';
import HeaderContainer from '../common/containers/headerContainer';
import MainLayout from '../layouts/mainLayout';
import AdminCreateContainer from '../modules/admin/containers/adminCreateContainer';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

const AdminCreatePage = ({ history }) => {
  checkTokenIsStillValid();

  return (
    <MainLayout>
      <HeaderContainer tab="admin" btnUrl="/admin" btnText="Back to user administration" />
      <AdminCreateContainer history={history} />
    </MainLayout>
  );
};

export default AdminCreatePage;
