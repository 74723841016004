import React from 'react';
import find from 'lodash/find';
import HeaderContainer from '../common/containers/headerContainer';
import MainLayout from '../layouts/mainLayout';
import AdminDetailsComponent from '../modules/admin/components/adminDetails';
import AdminService from '../modules/admin/services/admin';
import Loader from '../common/components/Loader';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

function prepareUserForRequest(data) {
  return {
    user: {
      id: data.id,
    },
  };
}

export default class AdminDetailsPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    checkTokenIsStillValid();

    this.state = {
      loading: true,
      error: false,
      user: null,
      openActiveModal: false,
      openDeactiveModal: false,
      openDeleteModal: false,
    };

    this.onSelectChange = this.onSelectChange.bind(this);
    this.activeChange = this.activeChange.bind(this);
    this.onModalChange = this.onModalChange.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentWillMount() {
    let self = this;
    const id = Number(this.props.match.params.id);
    AdminService.fetchUsers()
      .then(users => {
        const foundUser = find(users, { id });
        if (foundUser) {
          self.setState({
            user: foundUser,
            loading: false,
          });
        } else {
          self.setState({ loading: false, error: true });
        }
      })
      .catch(err => {
        self.setState({ loading: false, error: true });
      });
  }

  onSelectChange(e) {
    const self = this;
    let data = prepareUserForRequest(this.state.user);
    data.user.role = e.target.value;

    AdminService.updateUser(data).then(user => {
      self.setState({ user });
    });
  }

  onModalChange(field, value) {
    this.setState({ [field]: value });
  }

  closeAllModals() {
    this.setState({
      openActiveModal: false,
      openDeactiveModal: false,
      openDeleteModal: false,
    });
  }

  activeChange(value) {
    const self = this;
    let data = prepareUserForRequest(this.state.user);
    data.user.is_active = value;

    AdminService.updateUser(data).then(user => {
      self.setState({ user });
      self.closeAllModals();
    });
  }

  deleteUser() {
    const data = {
      user_id: this.state.user.id,
    };
    AdminService.deleteUser(data).then(result => {
      window.location.href = '/admin';
    });
  }

  render() {
    return (
      <MainLayout background="#f2f3f4">
        <HeaderContainer
          search={this.props.location.search}
          history={this.props.history}
          tab="admin"
          btnUrl="/admin"
          btnText="Back to user administration"
        />
        {this.state.loading ? <Loader /> : ''}
        {this.state.loading === false && this.state.error === false ? (
          <AdminDetailsComponent
            user={this.state.user}
            onSelectChange={this.onSelectChange}
            activeChange={this.activeChange}
            onModalChange={this.onModalChange}
            deleteUser={this.deleteUser}
            openActiveModal={this.state.openActiveModal}
            openDeactiveModal={this.state.openDeactiveModal}
            openDeleteModal={this.state.openDeleteModal}
          />
        ) : (
          ''
        )}
        {this.state.error === true ? (
          <div style={{ margin: '50px auto', opacity: '.5', fontSize: '18px' }}>
            No user with that ID found
          </div>
        ) : (
          ''
        )}
      </MainLayout>
    );
  }
}
