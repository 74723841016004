import React from 'react';
import styled from 'styled-components';
import { History } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ListItem = styled.div`
  padding: 5px 15px;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  display: flex;
  margin-bottom: 2px
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  &:hover{
    background: #ecf5fa;
    border: 1px solid #2D9CDB;
  }
`;
const LinkConn = styled(Link)`
  width: 100%;
`;
const IconStyles = {
  verticalAlign: 'bottom',
  fontSize: '16px',
  marginRight: '15px',
};

const ItemTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
`;
const NumberCircle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  background-color: rgba(43, 61, 80, 0.1);
  padding: 5px;
  width: 15px;
  height: 15px;
  text-align: center;
  color: rgba(43, 61, 80, 0.8);
  border-radius: 100px;
`;
const MissingText = styled.p`
  color: rgba(43, 61, 80, 0.7);
  font-size: 16px;
`;

function prepareDate(date) {
  const string = moment(date).toISOString();
  const newDate = moment(string).format('DD/MM/YYYY hh:mm:ss a');
  return newDate;
}
const CartListElement = ({ list }) => (
  <React.Fragment>
    {list.length === 0 ? <MissingText> You haven’t sent any carts yet. </MissingText> : ''}
    {list.map((item, key) => (
      <LinkConn key={key} to={`/orders/${item.id}`}>
        <ListItem key={item.id}>
          <ItemTitle>
            <History style={IconStyles} />{' '}
            {item.created ? `Cart request from ${prepareDate(item.created)}` : item.name}
          </ItemTitle>
          <NumberCircle>{item.cartitem_set.length}</NumberCircle>
        </ListItem>
      </LinkConn>
    ))}
  </React.Fragment>
);

export default CartListElement;
