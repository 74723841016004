import React from 'react';
import ReactDOM from 'react-dom';

import 'tachyons/css/tachyons.css';
import 'font-awesome/css/font-awesome.css';

// import './index.css';
// import './core/styles/styles.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import store from './store';

ReactDOM.render(<App store={store} />, document.getElementById('root'));
// registerServiceWorker();
unregister();
