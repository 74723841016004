import React from 'react';
import styled from 'styled-components';
import ItemNoBox from '../../../../common/components/ItemNoBox';

const itemNrLabel = styled.p`
  color: #000000;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
`;
const itemNrValue = styled.p`
  color: rgba(43, 61, 80, 0.8);
  text-align: left;
  font-size: 15px;
`;

const PartNoComponent = ({ newValue, oldValue, weight }) => {
  if (typeof weight === 'string') {
    weight = parseFloat(weight, 10);
  }

  return (
    <React.Fragment>
      <ItemNoBox
        ElementNrLabel={itemNrLabel}
        ElementNrValue={itemNrValue}
        title="Article number"
        value={newValue}
      />
      <ItemNoBox
        ElementNrLabel={itemNrLabel}
        ElementNrValue={itemNrValue}
        title="Old article number"
        value={oldValue}
      />
      <ItemNoBox
        ElementNrLabel={itemNrLabel}
        ElementNrValue={itemNrValue}
        title="Item weight"
        value={weight === 0 ? 'Not available' : `${weight} Kg`}
      />
    </React.Fragment>
  );
};

export default PartNoComponent;
