import React from 'react';
import { Link } from 'react-router-dom';
import {
  Home,
  ShoppingCart,
  FolderOpen,
  Restore,
  Star,
  Portrait,
  PowerSettingsNew,
} from '@material-ui/icons';
import styled from 'styled-components';
import { deleteCookie } from '../../core/cookie/cookie';

const SidebarContainer = styled.div`
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  z-index: 9999;
  width: 300px;
  height: 100vh;
  overflow: hidden scroll;
  scroll-behavior: smooth;
  background: #fafafa;
  -webkit-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.2);
`;
const InfoContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 300px;
  height: 250px;
`;
const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
  left: 0;
  z-index: 999;
`;
const LinksContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 300px;
  margin: 20px 0;
`;
const TermsContainer = styled.div`
  margin: 0 25px;
  padding: 20px 0;
  border-top: 1px solid #e6e6e6;
  text-align: center;
`;
const LinkRow = styled.div`
  padding: 18px 0 18px 20px;
`;
const ImgBackground = styled.img`
  width: 100%;
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  margin: auto auto;
`;
const RedStripe = styled.div`
  height: 75px;
  width: 100%;
  border: none;
  background-color: #e30412;
  position: absolute;
  bottom: 0px;
  left: 0px;
`;
const LinkText = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  vertical-align: middle;
`;
const Logged = styled.p`
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  padding: 0 15px;
`;
const User = styled(Logged)`
  font-size: 15px;
  font-weight: 500;
`;
let iconStyles = {
  fontSize: '20px',
  verticalAlign: 'middle',
  marginRight: '30px',
};

const logout = () => {
  deleteCookie();
  window.location.href = '/login';
};

const Sidebar = ({ tab, currentUser }) => (
  <React.Fragment>
    <div>
      <SidebarContainer id="sidenav" className="sidenav">
        <div id="holder-div">
          <InfoContainer>
            <ImgBackground id="sidenav-img-1" src="/images/info_bckgrd.png" />
            <RedStripe id="sidenav-log-info">
              <Logged id="sidenav-log-info1">Logged in as:</Logged>
              <User id="sidenav-log-info2">{currentUser.email}</User>
            </RedStripe>
          </InfoContainer>
          <LinksContainer id="sidenav-container">
            <Link to="/">
              <LinkRow>
                <Home style={iconStyles} nativeColor={tab === 'home' ? '#E30412' : ''} />
                <LinkText>DESparts Home</LinkText>
              </LinkRow>
            </Link>
            <Link to="/cart">
              <LinkRow>
                <ShoppingCart style={iconStyles} nativeColor={tab === 'cart' ? '#E30412' : ''} />
                <LinkText>Cart current</LinkText>
              </LinkRow>
            </Link>
            <Link to="/savedcarts">
              <LinkRow>
                <FolderOpen
                  style={iconStyles}
                  nativeColor={tab === 'savedcarts' ? '#E30412' : ''}
                />
                <LinkText>Cart template</LinkText>
              </LinkRow>
            </Link>
            <Link to="/orders">
              <LinkRow>
                <Restore style={iconStyles} nativeColor={tab === 'orders' ? '#E30412' : ''} />
                <LinkText>Cart request</LinkText>
              </LinkRow>
            </Link>
            <Link to="/favorites">
              <LinkRow>
                <Star style={iconStyles} nativeColor={tab === 'favourites' ? '#E30412' : ''} />
                <LinkText>Favorite articles</LinkText>
              </LinkRow>
            </Link>
            {currentUser.is_admin ? (
              <Link to="/admin">
                <LinkRow>
                  <Portrait style={iconStyles} nativeColor={tab === 'admin' ? '#E30412' : ''} />
                  <LinkText>User administration</LinkText>
                </LinkRow>
              </Link>
            ) : (
              ''
            )}
            <Link to="/">
              <LinkRow onClick={logout}>
                <PowerSettingsNew style={iconStyles} />
                <LinkText>Log out</LinkText>
              </LinkRow>
            </Link>
          </LinksContainer>
        </div>
        <TermsContainer id="sidenav-terms">
          <Link to="/terms">
            <LinkText>Read our TOS and Privacy Policy</LinkText>
          </Link>
        </TermsContainer>
      </SidebarContainer>
    </div>
    <Overlay id="overlay" />
  </React.Fragment>
);

export default Sidebar;

Sidebar.defaultProps = {
  tab: '',
};
