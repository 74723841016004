import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';
import HeaderContainer from '../common/containers/headerContainer';
import SearchComponent from '../modules/parts/components/searchComponent';
import PartsListComponent from '../modules/parts/components/partsListComponent';
import MainLayout from '../layouts/mainLayout';
import Container from '../common/components/Container';
import PartsService from '../modules/parts/services/parts';
import CartService from '../modules/cart/services/cart';
import Loader from '../common/components/Loader';

const QRButton = styled.button`
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(43, 61, 80, 0.1);
  padding: 10px 5px;
  margin-top: 5px;
  img {
    vertical-align: text-bottom;
    margin-right: 5px;
  }
`;

export default class PartsListPage extends React.Component {
  constructor() {
    super();

    checkTokenIsStillValid();

    this.state = {
      favorites: [],
      sentCarts: [],
      lastViewed: [],
      selected: 'sentCarts',
      loading: true,
      error: null,
    };
    this.goToScanPage = this.goToScanPage.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  componentWillMount() {
    const self = this;
    PartsService.getFavoriteParts()
      .then(favorites => {
        self.setState({ favorites });
        return CartService.getSentCarts();
      })
      .then(sentCarts => {
        self.setState({ sentCarts });
        return PartsService.fetchLastViewedParts();
      })
      .then(lastViewed => self.setState({ lastViewed, loading: false }))
      .catch(error => self.setState({ error, loading: false }));
  }

  onSelectChange(selected) {
    this.setState({ selected });
  }

  goToScanPage() {
    window.location.href = '/scan-qr';
  }

  render() {
    return (
      <MainLayout background="#f2f3f4">
        <HeaderContainer tab="home" />
        <Container maxWidth="560px" padding="0 10px">
          <SearchComponent history={this.props.history} />
          {isMobile ? (
            <QRButton onClick={this.goToScanPage}>
              <img src="./images/qr.png" alt="" />
              Scan QR Code
            </QRButton>
          ) : (
            ''
          )}
          {this.state.loading ? (
            <Loader />
          ) : (
            <PartsListComponent
              error={this.state.error}
              onSelectChange={this.onSelectChange}
              selected={this.state.selected}
              list={this.state[this.state.selected]}
            />
          )}
        </Container>
      </MainLayout>
    );
  }
}
