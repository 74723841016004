import find from 'lodash/find';

export const formatPart = (part, favoriteParts) => {
  const foundPart = find(favoriteParts, { id: part.id });
  if (foundPart) {
    part.favorite = true;
  } else {
    part.favorite = false;
  }
  return part;
};
