import React from 'react';
import styled from 'styled-components';
import Logo from '../../../common/components/Logo';
import Input from '../../../common/components/Input';
import ResetPassButton from '../../../common/components/LargeButton';
import BackToLoginButton from '../../../common/components/SmallButton';
import LoginService from '../services/login';
import Container from '../../../common/components/Container';
import { validateResetPassword } from '../logic/validateResetPassword';
import { deleteCookie } from '../../../core/cookie/cookie';

const buttonStyleActive = styled.button`
  color: #fff;
  width: 100%;
  background: #4c9bd6;
  border: 1px solid #478dc1;
  border-radius: 3px;
  padding: 10px;
`;
const Title = styled.h1`
  font-size: 18px;
  font-weight: 300;
  margin: 10px 0;
`;
const Text = styled.p`
  color: rgba(43, 61, 80, 0.5);
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin: 30px 10px 0 0;
`;
const ErrorText = styled.p`
  color: #eb5757;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 0 0;
  text-align: left;
  width: 100%;
`;

export default class ResetPassword extends React.Component {
  constructor() {
    super();

    this.state = {
      password: '',
      password_repeat: '',
      error: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.toLogin = this.toLogin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      error: null,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  toLogin() {
    window.location.href = '/login';
  }

  handleSubmit() {
    // eslint-disable-next-line
    const { password, password_repeat } = this.state;
    const { token, uidb64 } = this.props;

    const urlParams = `${uidb64}/${token}/`;
    validateResetPassword(password, password_repeat)
      .then(() => {
        LoginService.resetPassword(password, password_repeat, urlParams).then(() => {
          deleteCookie();
          window.location.href = '/';
        });
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  render() {
    // eslint-disable-next-line
    const { error, password, password_repeat } = this.state;

    return (
      <Container maxWidth="450px" margin="auto">
        <Logo />
        <Title>Set a new password</Title>
        <Text>
          The password should be at least 10 characters long and contain at least one number
          (1,2,3), one uppercase (A,B,C), one lowercase (a,b,c) letter and one special character
          (!,@, §).
        </Text>
        {error ? <ErrorText>{error}</ErrorText> : ''}
        <Input
          type="password"
          error={error}
          label="New Password"
          placeholder="Input your new password here"
          value={password}
          name="password"
          handleOnChange={this.handleChange}
          commonMargin="30px 0 0 0"
        />
        <Input
          type="password"
          error={error}
          label="Repeat Password"
          placeholder="Repeat your new password here"
          // eslint-disable-next-line
          value={password_repeat}
          name="password_repeat"
          handleOnChange={this.handleChange}
        />
        <ResetPassButton
          value="Set new password"
          ButtonElement={buttonStyleActive}
          onSubmit={this.handleSubmit}
          margin="30px 0 0 0"
        />
        <BackToLoginButton
          value="Cancel and back to log in"
          onSubmit={this.toLogin}
          margin="10px 0 0 0"
        />
      </Container>
    );
  }
}
