import React from 'react';
import filter from 'lodash/filter';
import { createFilter } from 'react-search-input';
import HeaderContainer from '../common/containers/headerContainer';
import AdminListComponent from '../modules/admin/components/adminList';
import MainLayout from '../layouts/mainLayout';
import AdminService from '../modules/admin/services/admin';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

function getType(value) {
  switch (value) {
    case 'Admins':
      return 'is_admin';
    case 'Users':
      return 'is_user';
    case 'Members':
      return 'is_member';
    default:
      break;
  }
  return null;
}

export default class AdminListPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    checkTokenIsStillValid();

    this.state = {
      loading: true,
      error: false,
      selected: 'All',
      selectChoice: ['All', 'Users', 'Members', 'Admins', 'Deactivated'],
      users: [],
      selectedArray: [],
    };

    this.onSelect = this.onSelect.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    const self = this;
    AdminService.fetchUsers().then(users => {
      users = users.reverse();
      self.setState({ users, selectedArray: users });
    });
  }

  onSelect(selected) {
    const type = getType(selected);
    if (type) {
      this.setState({
        selected,
        selectedArray: filter(this.state.users, { [type]: true, is_active: true }),
      });
    } else if (selected === 'Deactivated') {
      this.setState({ selected, selectedArray: filter(this.state.users, { is_active: false }) });
    } else {
      this.setState({ selected, selectedArray: this.state.users });
    }
  }

  onSearch(e) {
    const value = e.target.value;
    if (value.length === 0) {
      this.onSelect(this.state.selected);
    } else {
      const searchResults = this.state.users.filter(createFilter(value, 'fullname'));
      this.setState({ selectedArray: searchResults });
    }
  }

  render() {
    return (
      <MainLayout background="#f2f3f4">
        <HeaderContainer tab="admin" btnUrl="/admin" btnText="Navigation" />
        <AdminListComponent
          onSearch={this.onSearch}
          selectChoice={this.state.selectChoice}
          selected={this.state.selected}
          array={this.state.selectedArray}
          onSelectValue={this.onSelect}
        />
      </MainLayout>
    );
  }
}
