import React from 'react';
import TermsComponent from '../modules/login/components/terms';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

const TermsPage = ({ history }) => {
  checkTokenIsStillValid();

  return (
    <React.Fragment>
      <TermsComponent history={history} />
    </React.Fragment>
  );
};

export default TermsPage;
