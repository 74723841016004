import request from 'superagent';
import SuperagentPromisePlugin from 'superagent-promise-plugin';
import { getCookie } from '../../../core/cookie/cookie';

// const flatten = (arr, depth = 1) =>
//   depth !== 1
//     ? arr.reduce((a, v) => a.concat(Array.isArray(v) ? flatten(v, depth - 1) : v), [])
//     : arr.reduce((a, v) => a.concat(v), []);

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getSentCarts","saveCartTemplate", "getSavedCarts", "retrieveCart", "emptyCart", "emailCart", "removePartFromCart", "addPartsFromOtherCart", "sendCurrentCart", "deleteCart", "updateCart"] }] */
class CartService {
  async getSentCarts() {
    try {
      const response = await request
        // .get('https://sparepartordermanager.five.netsyno.com/api/sent_carts')
        .get(`/api/sent_carts`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async getSavedCarts() {
    try {
      const response = await request
        // .get('https://sparepartordermanager.five.netsyno.com/api/template_carts')
        .get(`/api/template_carts`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async retrieveCart(id) {
    try {
      const response = await request
        // .get(`https://sparepartordermanager.five.netsyno.com/api/retrieve_cart/${id}`)
        .get(`/api/retrieve_cart/${id}`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async emptyCart(cart_id) {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/empty_cart`)
        .post(`/api/empty_cart`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send({ cart_id });

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async emailCart(email, cart_id) {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/email_cart`)
        .post(`/api/email_cart`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send({ cart_id, email });

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async removePartFromCart(cart_id, spare_part_id) {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/remove_spare_part_from_cart`)
        .post(`/api/remove_spare_part_from_cart`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send({ cart_id, spare_part_id });

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async sendCurrentCart(cart_id) {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/send_cart`)
        .post(`/api/send_cart`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send({ cart_id });

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async deleteCart(cart_id) {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/delete_cart`)
        .post(`/api/delete_cart`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send({ cart_id });

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async updateCart(cart) {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/update_cart`)
        .post(`/api/update_cart`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send({ cart });

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async saveCartTemplate({ cart_id, cart_name }) {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/save_cart_template`)
        .post(`/api/save_cart_template`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send({ cart_id, cart_name });

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }
}

export default new CartService();
