import { connect } from 'react-redux';
import CartComponent from '../components/cart';
import {
  removeFromCart,
  toggleModal,
  updateCart,
  emptyAll,
  updateQuantityForItem,
  emailCart,
  sendCart,
  sendCartUpdate,
} from '../actions/cart';
import { updateParts } from '../../parts/actions/parts';
import { calculateTotal } from '../logic/cartTotal';

const mapStateToProps = (state, props) => {
  let total = calculateTotal(state.cart);
  return {
    cart: state.cart,
    grandTotal: total,
    history: props.history,
    userEmail: state.login.currentUser.email,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (cart, history) => {
      dispatch(sendCart(cart.id, history));
    },
    removeFromCart: (cartID, partID) => {
      dispatch(removeFromCart(cartID, partID));
    },
    onModalChange: (field, value) => {
      dispatch(toggleModal(field, value));
    },
    onModalSubmit: cart => {
      dispatch(sendCartUpdate(cart));
    },
    onChange: e => {
      dispatch(updateCart(e.target.name, e.target.value));
    },
    onQuantityChange: (index, value, id) => {
      dispatch(updateQuantityForItem(index, value));
    },
    onEmptyAll: id => {
      dispatch(emptyAll(id));
      dispatch(updateParts('toCart', false));
    },
    onSendEmailWithCart: (email, id, history) => {
      dispatch(emailCart(email, id, history));
    },
    openPrint: () => {
      window.print();
    },
  };
};

const DoctorListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartComponent);

export default DoctorListContainer;
