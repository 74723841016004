import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { Print, DeleteForever } from '@material-ui/icons';
import ItemContainer from './cartElements/itemContainer';
import AddParts from '../../../common/components/LargeButton';
import DeleteBtn from '../../../common/components/IconButton';
import ConfirmModal from '../../../common/components/ConfirmModal';
import Container from '../../../common/components/Container';
import config from '../../../core/config/config.json';
import CartService from '../services/cart';
import PrintCart from './cartElements/printCart';
import { addFromSavedCarts } from '../actions/cart';

const TotalBlock = styled.div`
  min-height: 55px;
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
const TotalText = styled.p`
  color: rgba(43, 61, 80, 0.7);
  font-size: 14px;
  text-align: left;
  line-height: 17px;
  width: 60%;
`;
const TotalCont = styled.div`
  margin: 15px 0;
  text-align: center;
`;
const ItemSubtitle = styled.div`
  font-size: 13px;
  flex: 1 1 60%;
  text-align: left;
  font-weight: 600;
`;
const QuantitySubtitle = styled.div`
  font-size: 13px;
  flex: 1 1 20%;
  text-align: left;
  font-weight: 600;
`;
const SubtotalSubtitle = styled.div`
  font-size: 13px;
  flex: 1 1 20%;
  text-align: right;
  font-weight: 600;
`;
const SubtitleCont = styled.div`
  min-height: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
const TitleCont = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  @media (max-width: 400px) {
    align-items: flex-start;
  }
`;
const Title = styled.p`
  color: rgba(43, 61, 80, 0.8);
  font-size: 30px;
  font-weight: 600;
  margin: 0px;

  @media (max-width: 400px) {
    font-size: 24px;
  }
`;
const buttonStyleActive = styled.button`
  color: #fff;
  width: 100%;
  background: #4c9bd6;
  border: 1px solid #478dc1;
  border-radius: 3px;
  padding: 10px;
`;
const TotalLabel = styled.label`
  color: #000;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  text-align: right;
`;
const TotalPrice = styled.p`
  color: rgba(43, 61, 80, 0.6);
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
  text-align: right;
`;
const IconPrintStyle = {
  color: '#2D9CDB',
  marginLeft: '20px',
  fontSize: '16px',
  verticalAlign: 'middle',
  cursor: 'pointer',
};

function checkIfItemsAreAlreadyAdded(storeCartItems, currentCart) {
  const foundItems = filter(storeCartItems, item => {
    return find(currentCart.cartitem_set, {
      spare_part: { id: item.spare_part.id },
    });
  });
  if (foundItems.length > 0 && foundItems.length === currentCart.cartitem_set.length) {
    return true;
  }
  return false;
}

class SavedCartsDetails extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      openDeleteModal: false,
      cart: {},
      grandTotal: 0,
      alreadyAdded: false,
    };

    this.setData = this.setData.bind(this);
    this.addParts = this.addParts.bind(this);
  }

  componentWillMount() {
    this.setData(this.props.cart);
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps;
    this.setData(this.props.cart);
  }

  setData(cart) {
    const { storeCartItems } = this.props;
    const alreadyAdded = checkIfItemsAreAlreadyAdded(storeCartItems, cart);
    let grandTotal = 0;
    cart.cartitem_set.forEach(item => {
      if (item.spare_part.price) {
        grandTotal += Number(item.spare_part.price) * item.quantity;
      }
    });
    this.setState({ cart, grandTotal, alreadyAdded });
  }

  addParts(cart) {
    this.props.addFromSavedCarts(cart.cartitem_set);
    this.setState({
      alreadyAdded: true,
    });
  }

  render() {
    const { userEmail } = this.props;
    return (
      <React.Fragment>
        {isEmpty(this.state.cart) === false ? (
          <PrintCart
            userEmail={userEmail}
            cart={this.state.cart}
            grandTotal={this.state.grandTotal}
          />
        ) : (
          ''
        )}
        <div id="react-no-print">
          <Container>
            {isEmpty(this.state.cart) ? (
              <div style={{ margin: '50px auto', opacity: '.5', fontSize: '18px' }}>
                No cart found
              </div>
            ) : (
              <React.Fragment>
                <TitleCont>
                  <Title>
                    {this.state.cart.name}
                    <Print
                      onClick={() => {
                        window.print();
                      }}
                      style={IconPrintStyle}
                    />
                  </Title>
                  <div>
                    <DeleteBtn
                      onClick={() =>
                        this.setState({
                          openDeleteModal: true,
                        })
                      }
                      background="transparent"
                      border="1px solid transparent"
                      padding="0px"
                      Icon={DeleteForever}
                      iconAlign="middle"
                      iconMargin="0 5px"
                      iconColor="#EB5757"
                      iconSize="16px"
                      color="#EB5757"
                      value="Delete"
                    />
                  </div>
                </TitleCont>
                {this.state.cart.cartitem_set.length === 0 ? (
                  ''
                ) : (
                  <SubtitleCont>
                    <ItemSubtitle>
                      <span>Item</span>
                    </ItemSubtitle>
                    <QuantitySubtitle>
                      <span>Quantity</span>
                    </QuantitySubtitle>
                    <SubtotalSubtitle>
                      <span>Current subtotal</span>
                    </SubtotalSubtitle>
                  </SubtitleCont>
                )}
                {this.state.cart.cartitem_set.map((item, key) => (
                  <React.Fragment key={key}>
                    <ItemContainer
                      history={this.props.history}
                      replacement={item.spare_part.replacement}
                      hasUnderline="none"
                      disableInput
                      id={item.spare_part.id}
                      itemNo={item.spare_part.item_no}
                      itemNoOldsystem={item.spare_part.item_no_old}
                      name={item.spare_part.name}
                      currency={config.currency}
                      totalPrice={item.spare_part.price * item.quantity}
                      quantity={item.quantity}
                      index={key}
                      src={item.spare_part.image ? item.spare_part.image : config.missing_image}
                      unitsInStock={item.spare_part.units_in_stock}
                      isInStock={item.spare_part.in_stock}
                      available
                    />
                  </React.Fragment>
                ))}
                <TotalBlock>
                  <TotalText>
                    Click the button below to add these parts to your current cart.{' '}
                  </TotalText>
                  <TotalCont>
                    <TotalLabel>Grand total</TotalLabel>
                    <TotalPrice>{`${this.state.grandTotal} ${config.currency}`}</TotalPrice>
                  </TotalCont>
                </TotalBlock>
                {this.state.alreadyAdded ? (
                  <AddParts
                    color="rgba(43, 61, 80, 0.4);"
                    border="1px solid rgba(43, 61, 80, 0.15);"
                    background="#F5F5F5;"
                    value="Parts already in cart"
                  />
                ) : (
                  <AddParts
                    ButtonElement={buttonStyleActive}
                    value="Add spare parts to current cart"
                    onSubmit={() => this.addParts(this.state.cart)}
                  />
                )}
                {this.state.openDeleteModal ? (
                  <ConfirmModal
                    title="Do you really want to delete this cart?"
                    confirmText="Delete"
                    btnColor="#EB5757"
                    confirm={() => {
                      const parentPage = window.location.pathname.replace(/^\/([^/]*).*$/, '$1');
                      CartService.deleteCart(this.state.cart.id).then(() => {
                        this.setState({ openDeleteModal: false });
                        window.location.href = `/${parentPage}`;
                      });
                    }}
                    closeModal={() =>
                      this.setState({
                        openDeleteModal: false,
                      })
                    }
                  />
                ) : (
                  ''
                )}
              </React.Fragment>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    userEmail: state.login.currentUser.email,
    storeCartItems: state.cart.cartitem_set,
  }),
  {
    addFromSavedCarts,
  }
)(SavedCartsDetails);
