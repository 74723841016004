import React from 'react';
import styled from 'styled-components';

const PriceLabel = styled.p`
  color: #000;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
`;
const AdminPrice = styled.p`
  color: #eb5757;
  text-align: left;
  font-size: 15px;
`;
const PriceValue = styled.p`
  color: rgba(43, 61, 80, 0.8);
  text-align: left;
  font-size: 15px;
`;
const OnRequest = styled.p`
  color: #f2994a;
  text-align: left;
  font-size: 15px;
`;

const ItemPrice = ({ currency, value, label, available }) => {
  if (typeof value === 'string') {
    value = parseInt(value, 10);
  }

  return (
    <React.Fragment>
      {window.location.href.indexOf('/item/124') > 0 ||
      window.location.href.indexOf('/item/125') > 0 ? (
        <AdminPrice>DESMA Price</AdminPrice>
      ) : (
        <PriceLabel>Price per item</PriceLabel>
      )}
      {/* {available !== 0
        ? <PriceValue>{value + ' ' + currency}</PriceValue>
        : <OnRequest>On request</OnRequest>
      } */}
      {window.location.href.indexOf('/item/126') > 0 ? (
        <OnRequest>On request</OnRequest>
      ) : (
        <PriceValue>{value === 0 ? 'Not available' : value + ' ' + currency}</PriceValue>
      )}
    </React.Fragment>
  );
};

export default ItemPrice;
