import { handleActions } from 'redux-actions';

import { LANGUAGE_CHANGED } from '../actions/constants';

export default handleActions(
  {
    [LANGUAGE_CHANGED]: (state, action) => action.payload,
  },
  { locale: 'en', messages: {} }
);
