import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  background: transparent;
  padding: ${props => (props.padding ? props.padding : '0px 15px 0px 15px')};
  margin: ${props => (props.margin ? props.margin : '30px auto')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '600px')};
  flex-flow: ${props => (props.flexFlow ? props.flexFlow : 'column nowrap')};
  height: ${props => (props.height ? props.height : 'auto')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
`;

const ContainerNormal = props => (
  <Container
    margin={props.margin}
    padding={props.padding}
    maxWidth={props.maxWidth}
    flexFlow={props.flexFlow}
    justifyContent={props.justifyContent}
    alignItems={props.alignItems}
    height={props.height}
  >
    {props.children}
  </Container>
);

export default ContainerNormal;
