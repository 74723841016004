import React from 'react';
import Container from './Container';
import Logo from './Logo';

const SuccessWithoutHeader = ({ children }) => (
  <Container maxWidth="450px" margin="auto">
    <Logo />
    {children}
  </Container>
);

export default SuccessWithoutHeader;
