import React from 'react';
import styled from 'styled-components';

const ButtonElement = styled.button`
  color: ${props => (props.color ? props.color : '#fff')};
  width: ${props => (props.width ? props.width : '')};
  background: ${props => (props.background ? props.background : '#2D9CDB')};
  border: ${props => (props.border ? props.border : '1px solid #478dc1')};
  border-radius: 3px;
  margin: ${props => (props.margin ? props.margin : '0px')};
  height: ${props => (props.height ? props.height : 'auto')};
  cursor: pointer;
  font-family: 'Exo 2', sans-serif;
  padding: ${props => (props.padding ? props.padding : '10px')};
`;

const Text = styled.p`
  margin: 0;
  display: inline-block;
  vertical-align: middle;

  @media (max-width: 499px) {
    display: ${props => (props.display ? props.display : 'inline-block')};
  }
`;

const IconButton = ({
  Icon,
  iconColor,
  iconSize,
  iconMargin,
  iconAlign,
  onClick,
  value,
  display,
  color,
  padding,
  background,
  border,
  width,
  height,
  margin,
  onSubmit,
}) => (
  <React.Fragment>
    <ButtonElement
      onClick={onClick}
      color={color}
      background={background}
      border={border}
      value={value}
      width={width}
      padding={padding}
      margin={margin}
      height={height}
    >
      {typeof Icon !== 'undefined' ? (
        <Icon
          style={{
            color: iconColor,
            fontSize: iconSize,
            margin: iconMargin,
            verticalAlign: iconAlign,
          }}
        />
      ) : (
        ''
      )}
      <Text display={display}>{value}</Text>
    </ButtonElement>
  </React.Fragment>
);

export default IconButton;
