const formatPartsArray = (array, key, value) => {
  return array.map((item, index) => {
    return {
      quantity: index === key ? Number(value) : item.quantity,
      spare_part: item.spare_part.id,
    };
  });
};

export const prepareData = (cart, key, value) => {
  cart.cartitem_set = formatPartsArray(cart.cartitem_set, key, value);
  return cart;
};
