import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Exo 2';
    src: url('/fonts/Exo2-Medium.ttf');
    font-weight: 500;
  }
  @font-face {
    font-family: 'Exo 2';
    src: url('/fonts/Exo2-Regular.ttf');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Exo 2';
    src: url('/fonts/Exo2-SemiBold.ttf');
    font-weight: 600;
  }
  @font-face {
    font-family: 'Exo 2';
    src: url('/fonts/Exo2-Bold.ttf');
    font-weight: 700;
  }
  body {
    font-family: 'Exo 2', sans-serif;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: var(--ssf-gray3);
    background-color: var(--ssf-gray1);
    width: 100%;
    height: 100%;
  }
  a {
    color: rgba(43,61,80,0.8);
    font-weight: 600;
    text-decoration: none;
  }
  button{
    cursor: pointer;
    outline: none;
    font-weight: 600;
  }
  input{
  }
  select{
  }
  .modal{
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 400px;
    max-width: 90vw;
    transform: translate(-50%, -50%);
    -webkit-box-shadow: 0px 24px 24px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 24px 24px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 24px 24px 0px rgba(0,0,0,0.3);
  }
  .modalOverlay{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.38);
  }
   @page {
    size: A4;
    margin: 11mm 17mm 17mm 17mm;
   }

   @media print {
    .footer {
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 297mm;
    }
   }
`;

export default GlobalStyle;
