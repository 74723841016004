import * as Constants from '../constants/constants';
import PartService from '../services/parts';
import { formatPart } from '../logic/populatePart';

export const update = (field, value) => {
  return {
    type: Constants.UPDATE_PARTS_STATE,
    field,
    value,
  };
};

export const updateParts = (field, value) => {
  return (dispatch, getState) => {
    if (field === 'favorite') {
      if (value.favorite === true) {
        PartService.removePartFromFavorites(value.id).then(result => {
          dispatch(update('favorite', false));
        });
      } else {
        PartService.addPartToFavorites(value.id).then(result => {
          dispatch(update('favorite', true));
        });
      }
    } else {
      dispatch(update(field, value));
    }
  };
};

export const addMoreToCart = () => {
  return dispatch => {
    dispatch({
      type: Constants.ADD_MORE_PARTS_TO_CART,
    });
  };
};

export const updateInitialAddedQuantity = quantity => {
  return dispatch => {
    dispatch({
      type: Constants.UPDATE_INITIAL_PART_ADDED_QUANTITY,
      quantity,
    });
  };
};

export const populatePartState = (part, foundInCart) => {
  return dispatch => {
    PartService.getFavoriteParts().then(array => {
      part = formatPart(part, array);
      dispatch({
        type: Constants.POPULATE_PARTS_STATE,
        part,
        foundInCart,
      });
    });
  };
};
