import { connect } from 'react-redux';
import DetailsComponent from '../components/details';
import { updateParts, addMoreToCart } from '../actions/parts';
import { updateCartItems } from '../../cart/actions/cart';

const DetailsContainer = connect(
  state => ({
    item: state.parts,
  }),
  dispatch => ({
    addToCart: item => {
      dispatch(updateCartItems(item, item.quantity));
    },
    onChange: e => {
      dispatch(updateParts('quantity', e.target.value));
    },
    addToFavorites: item => {
      dispatch(updateParts('favorite', item));
    },
    addMore: () => {
      dispatch(addMoreToCart());
    },
  })
)(DetailsComponent);

export default DetailsContainer;
