import React from 'react';
import ResetPasswordComponent from '../modules/login/components/resetPassword';

const ResetPasswordPage = ({ match }) => (
  <React.Fragment>
    <ResetPasswordComponent uidb64={match.params.uidb64} token={match.params.token} />
  </React.Fragment>
);

export default ResetPasswordPage;
