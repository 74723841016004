import cloneDeep from 'lodash/cloneDeep';
import * as Constants from '../constants/constants';

const initialState = {
  token: null,
  currentUser: null,
  loading: true,
};

const setToken = (state, token) => {
  const newState = cloneDeep(state);
  newState.token = token;
  return newState;
};

const setTerms = (state, user) => {
  const newState = cloneDeep(state);
  newState.currentUser.accepted_terms_and_privacy_policy = user.accepted_terms_and_privacy_policy;
  return newState;
};

const setCurrentUser = (state, user) => {
  const newState = cloneDeep(state);
  newState.currentUser = user;
  return newState;
};

const setLoading = (state, loading) => {
  const newState = cloneDeep(state);
  newState.loading = loading;
  return newState;
};

const partsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.SET_USER_TOKEN:
      return setToken(state, action.token);
    case Constants.SET_USER_TERMS:
      return setTerms(state, action.user);
    case Constants.SET_CURRENT_USER:
      return setCurrentUser(state, action.user);
    case Constants.SET_LOADING:
      return setLoading(state, action.loading);
    default:
      return state;
  }
};

export default partsReducer;
