import React from 'react';
import HeaderContainer from '../common/containers/headerContainer';
import CartListComponent from '../modules/cart/components/cartList';
import CartService from '../modules/cart/services/cart';
import MainLayout from '../layouts/mainLayout';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

function simulateClick() {
  /* simulate click to close navigation */
  const overlay = document.getElementById('overlay');
  if (overlay) {
    overlay.click();
  }
}

export default class CartListPage extends React.Component {
  constructor() {
    super();

    checkTokenIsStillValid();

    this.state = {
      pageUrl: null,
      listData: [],
      title: '',
      description: '',
      missing: '',
      tab: '',
      error: null,
      loading: true,
    };
  }

  componentDidMount() {
    simulateClick();
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    simulateClick();
    this.fetchData();
    /* if we switch between orders and savedcarts page we have to set the loading manually */
    this.setState({
      loading: true,
    });
  }

  fetchData() {
    const urlString = this.props.history.location.pathname;
    this.setState({
      pageUrl: urlString,
      title: urlString === '/orders' ? 'Cart request' : 'Cart template',
      description:
        urlString === '/orders'
          ? 'Those are the carts you have previously sent.'
          : 'Those are the carts you have saved.',
      missing:
        urlString === '/orders'
          ? 'You haven’t sent any carts yet.'
          : 'You haven’t saved any carts yet.',
      tab: urlString === '/orders' ? 'orders' : 'savedcarts',
    });

    if (urlString === '/orders') {
      CartService.getSentCarts()
        .then(listData => {
          this.setState({ listData, loading: false });
        })
        .catch(error => this.setState({ error, loading: false }));
    } else {
      CartService.getSavedCarts()
        .then(listData => {
          this.setState({ listData, loading: false });
        })
        .catch(error => this.setState({ error, loading: false }));
    }
  }

  render() {
    return (
      <MainLayout background="#f2f2f2">
        <HeaderContainer tab={this.state.tab} />
        <CartListComponent
          tab={this.state.tab}
          error={this.state.error}
          loading={this.state.loading}
          url={this.state.pageUrl}
          title={this.state.title}
          missing={this.state.missing}
          list={this.state.listData.reverse()}
          description={this.state.description}
        />
      </MainLayout>
    );
  }
}
