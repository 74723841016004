import cloneDeep from 'lodash/cloneDeep';
import * as Constants from '../constants/constants';

const testLogin = {
  time: '18.05.2018  13:30',
  ip: '85.255.255.18',
};

const initialState = {
  sentOrders: 4,
  savedCarts: 3,
  favoritedItems: 5,
  role: 'user',
  lastLogin: [testLogin, testLogin, testLogin, testLogin, testLogin, testLogin],
  active: true,
  deleted: false,
  openActiveModal: false,
  openDeactiveModal: false,
  openDeleteModal: false,
};

const updateState = (state, field, value) => {
  let newState = cloneDeep(state);
  if (field !== 'openActiveModal' && field !== 'openDeactiveModal' && field !== 'openDeleteModal') {
    newState.openActiveModal = false;
    newState.openDeactiveModal = false;
    newState.openDeleteModal = false;
  }
  newState[field] = value;

  return newState;
};

const adminListReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.ON_ADMIN_ROLE_SELECT:
      return updateState(state, 'role', action.role);
    case Constants.ON_ADMIN_ACTIVE_CHANGE:
      return updateState(state, 'active', action.value);
    case Constants.ON_ADMIN_UPDATE:
      return updateState(state, action.field, action.value);
    case Constants.ON_ADMIN_DELETE:
      return updateState(state, 'deleted', true);
    default:
      return state;
  }
};

export default adminListReducer;
