import React from 'react';
import styled from 'styled-components';

const TotalLabel = styled.p`
  color: #000;
  text-align: right;
  font-size: 13px;
  font-weight: 600;
`;

const TotalPriceStyle = styled.p`
  color: rgba(43, 61, 80, 0.6);
  text-align: right;
  font-size: 18px;
  font-weight: 700;
`;

const checkAvailable = (available, value, currency) => {
  if (typeof available !== 'undefined' && available && value && value > 0) {
    return (
      <TotalPriceStyle>
        {`${value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} ${currency}`}
      </TotalPriceStyle>
    );
  }

  return <TotalPriceStyle> - </TotalPriceStyle>;
};

const TotalPrice = ({ currency, value, label, available }) => (
  <React.Fragment>
    <TotalLabel>{label}</TotalLabel>
    {checkAvailable(available, value, currency)}
  </React.Fragment>
);

export default TotalPrice;
