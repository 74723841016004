import React from 'react';
import styled from 'styled-components';

const QuantityForm = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  align-items: left;
  position: relative;
`;
const QuantityLabel = styled.p`
  color: #000;
  font-size: 13px;
  font-weight: 600;
`;
const QuantityValue = styled.input`
  width: 70px;
  color: rgba(43, 61, 80, 0.6);
  font-size: 15px;
  border: none;
  border-bottom: ${props =>
    props.noUnderline ? props.noUnderline : '1px solid rgba(43, 61, 80, 0.1)'};
  padding-bottom: 10px;
  -moz-appearance: textfield;
  &:focus {
    border-bottom: 1px solid #5d9ad1;
    outline: none;
  }
  &:focus ~ ${QuantityLabel} {
    color: #5d9ad1;
    outline: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const Pcs = styled.span`
  color: rgba(43, 61, 80, 0.7);
  left: 45px;
  position: absolute;
  bottom: 10px;
`;
const ItemQuantity = ({ label, value, noUnderline, disableInput, handleOnChange }) => (
  <QuantityForm>
    <QuantityValue
      style={disableInput ? { pointerEvents: 'none' } : {}}
      type="number"
      noUnderline={noUnderline}
      inputMode="numeric"
      onKeyPress={e =>
        e.charCode === 101 || e.charCode === 46 || e.charCode === 45 || e.charCode === 43
          ? e.preventDefault()
          : null
      }
      pattern="[0-9]"
      value={value === 0 ? '' : value}
      onChange={handleOnChange}
    />
    {typeof label !== 'undefined' ? <QuantityLabel>{label}</QuantityLabel> : ''}
    <Pcs>pcs.</Pcs>
  </QuantityForm>
);

export default ItemQuantity;
