import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ImgComponent from '../../../../common/components/ItemImg';

const ContainerItem = styled.div`
  background: #fff;
  width: 100%;
  height: 75px;
  padding: 4px;
  overflow: hidden;
  display: flex;
  border: 1px solid transparent;
  border-radius: 3px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px
  position: relative;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  &:hover{
    background: #ecf5fa;
    border: 1px solid #2D9CDB;
  }
`;

const Div = styled.div`
  display: flex;
  flex: 1 1 60%;
`;
const ItemDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
`;
const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #2d9cdb;
  margin: 0;
`;
const ItemNr = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: rgba(43, 61, 80, 0.8);
  margin: 0;
`;
const NotInStock = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: rgb(235, 87, 87);
  margin: 10px 0 0 0;
`;
const InStock = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: #219653;
  margin: 10px 0 0 0;
`;
const LinkConn = styled(Link)`
  width: 100%;
`;

function Availability({ isInStock, units }) {
  if (!isInStock) {
    return <NotInStock>Not in stock</NotInStock>;
  }

  if (isInStock && units) {
    return <InStock>{units} in stock</InStock>;
  }

  if (isInStock && !units) {
    return <InStock>In stock</InStock>;
  }

  return null;
}

const itemContainer = ({
  src,
  index,
  name,
  itemNo,
  itemNoOldsystem,
  itemInStock,
  itemUnitsInStock,
  id,
}) => (
  <LinkConn to={`/item/${itemNo}?Favorites=/favorites`}>
    <ContainerItem key={index}>
      <Div>
        <ImgComponent src={src} placeHolderStyle="small" />
        <ItemDetails>
          <ItemTitle>{name}</ItemTitle>
          <ItemNr>{itemNoOldsystem ? `${itemNo}/${itemNoOldsystem}` : itemNo}</ItemNr>
          <Availability isInStock={itemInStock} units={itemUnitsInStock} />
        </ItemDetails>
      </Div>
    </ContainerItem>
  </LinkConn>
);

export default itemContainer;
