import request from 'superagent';
import SuperagentPromisePlugin from 'superagent-promise-plugin';
import { getCookie } from '../../../core/cookie/cookie';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getFavoriteParts", "findSpecificPart", "addPartToFavorites", "removePartFromFavorites", "fetchLastViewedParts", "addPartToCart"] }] */
class PartsService {
  async getFavoriteParts() {
    // use this in actual requests
    try {
      const response = await request
        // .get('https://sparepartordermanager.five.netsyno.com/api/favorite_spare_parts')
        .get(`/api/favorite_spare_parts`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async findSpecificPart(id) {
    try {
      const response = await request
        // .get(`https://sparepartordermanager.five.netsyno.com/api/retrieve_spare_part/${id}`)
        .get(`/api/retrieve_spare_part/${id}`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async addPartToFavorites(id) {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/add_spare_part_to_favorites`)
        .post(`/api/add_spare_part_to_favorites`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send({ spare_part_id: id });

      const responseJson = await response.body;
      return responseJson;
      // return dummy_add_to_favorites
    } catch (e) {
      throw e;
    }
  }

  async removePartFromFavorites(id) {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/remove_spare_part_from_favorites`)
        .post(`/api/remove_spare_part_from_favorites`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send({ spare_part_id: id });

      const responseJson = await response.body;
      return responseJson;
      // return dummy_remove_from_favorites
    } catch (e) {
      throw e;
    }
  }

  async fetchLastViewedParts() {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/last_viewed_spare_parts?n=5`)
        .get(`/api/last_viewed_spare_parts?n=5`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async addPartToCart(data) {
    try {
      const response = await request
        // .post(`https://sparepartordermanager.five.netsyno.com/api/add_spare_part_to_cart`)
        .post(`/api/add_spare_part_to_cart`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send(data);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }
}

export default new PartsService();
