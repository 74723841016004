import request from 'superagent';
import SuperagentPromisePlugin from 'superagent-promise-plugin';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["Login", "forgottenPassword", "resetPassword"] }] */
class LoginService {
  async Login(email, password) {
    try {
      const response = await request
        // .post('https://sparepartordermanager.five.netsyno.com/api/auth/')
        .post(`/api/auth/`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .send({ email, password });

      const responseJson = await response;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async forgottenPassword(email) {
    try {
      const response = await request
        // .post('/api/request_password_reset_email/')
        .post(`/api/request_password_reset_email/`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .send({ email });

      const responseJson = await response;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async resetPassword(password, passwordRepeat, urlParams) {
    try {
      const response = await request
        // .post(`/api/reset_password/${urlParams}`)
        .post(`/api/reset_password/${urlParams}`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .send({
          password1: password,
          password2: passwordRepeat,
        });

      const responseJson = await response;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }
}

export default new LoginService();
