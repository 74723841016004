import cloneDeep from 'lodash/cloneDeep';
import * as Constants from '../constants/constants';

const initialState = {
  userName: '',
  email: '',
  role: '',
  customerNumber: '',
  password: '',
  passwordRepeat: '',
  error: null,
  temp_role: '',
};

const updateState = (state, field, value) => {
  let newState = cloneDeep(state);
  newState[field] = value;
  newState.temp_role = '';
  newState.error = null;
  return newState;
};

const handleError = (state, error) => {
  let newState = cloneDeep(state);
  newState.error = typeof error.msg === 'undefined' ? error[0] : error;
  return newState;
};

const resetState = state => {
  state.userName = '';
  state.email = '';
  state.role = '';
  state.customerNumber = '';
  state.password = '';
  state.passwordRepeat = '';
  state.error = null;
  return state;
};

const afterAdminCreate = state => {
  let newState = cloneDeep(state);
  newState.temp_role = newState.role;
  newState = resetState(newState);
  return newState;
};

const adminListReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.ON_ADMIN_CREATE_UPDATE:
      return updateState(state, action.field, action.value);
    case Constants.ON_ADMIN_CREATE_SUBMIT_ERROR:
      return handleError(state, action.error);
    case Constants.ON_ADMIN_CREATE_SUBMIT_SUCCESS:
      return afterAdminCreate(state);
    default:
      return state;
  }
};

export default adminListReducer;
