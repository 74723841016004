import React from 'react';
import { PropagateLoader } from 'react-spinners';
import Styled from 'styled-components';

const PropagateHolder = Styled.div`
  width: 100%;
  margin: 50px 0;
  text-align: center;
  & > div {
    margin: auto;
    width: 1px;
  }
`;

const Loader = () => (
  <PropagateHolder>
    <PropagateLoader color={'#576471'} size={20} loading />
  </PropagateHolder>
);

export default Loader;
