import cloneDeep from 'lodash/cloneDeep';

export function formatBeforeUpdate(cart) {
  const temp = cloneDeep(cart);
  temp.cart_id = temp.id;
  temp.cartitem_set = temp.cartitem_set.map(item => ({
    quantity: item.quantity,
    spare_part: item.spare_part.id,
  }));
  return temp;
}
