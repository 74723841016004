import React from 'react';
import { Check } from '@material-ui/icons';
import AddToCart from '../../../../common/components/LargeButton';
import Added from '../../../../common/components/IconButton';

const AddedToCartButton = ({ onSubmit, total }) => (
  <React.Fragment>
    <Added
      color="#219653"
      Icon={Check}
      iconAlign="middle"
      iconColor="#219653"
      iconMargin="0 10px 0 0"
      iconSize="16px"
      background="transparent"
      border="1px solid transparent"
      value={`${total} in cart`}
      padding="10px 0"
      width="auto"
    />
    <AddToCart
      onSubmit={onSubmit}
      width="auto"
      color="#2D9CDB"
      background="transparent"
      border="1px solid #fff"
      value="Want to add more?"
      padding="10px 0"
    />
  </React.Fragment>
);

export default AddedToCartButton;
