import React from 'react';
import LoginComponent from '../modules/login/components/login';

const LoginPage = () => (
  <React.Fragment>
    <LoginComponent />
  </React.Fragment>
);

export default LoginPage;
