import asyncLoop from 'node-async-loop';
import cloneDeep from 'lodash/cloneDeep';
import * as Constants from '../constants/constants';
import PartsService from '../../parts/services/parts';
import CartService from '../services/cart';
import { updateParts } from '../../parts/actions/parts';
import { formatBeforeUpdate } from '../logic/formatCart';
import { prepareData } from '../logic/updateQuantity';
import { emailValidation } from '../../../core/validation/validation';

export const getParts = () => {
  return {
    type: 'GET_PART',
  };
};

export const updateCartItems = (item, quantity) => {
  return (dispatch, getState) => {
    const data = {
      spare_part_id: item.id,
      quantity,
      cart_id: getState().cart.id,
    };
    PartsService.addPartToCart(data).then(cart => {
      dispatch(updateParts('toCart', quantity));
      dispatch({
        type: Constants.ADD_ITEM_TO_CART,
        cart,
      });
    });
  };
};

export const updateCart = (field, value) => {
  return dispatch => {
    dispatch({
      type: Constants.UPDATE_CART_VALUE,
      field,
      value,
    });
  };
};

export const updateCartAfterSave = cart => {
  return dispatch => {
    dispatch({
      type: Constants.UPDATE_CART_VALUE_AFTER_SAVE,
      cart,
    });
  };
};

export const removeFromCart = (cartID, partID) => {
  return dispatch => {
    CartService.removePartFromCart(cartID, partID).then(cart =>
      dispatch({ type: Constants.REMOVE_ITEM_FROM_CART, cart })
    );
  };
};

export const toggleModal = (field, value) => {
  return dispatch => {
    dispatch({
      type: Constants.TOGGLE_CART_MODAL,
      field,
      value,
    });
  };
};

export const emptyAll = id => {
  return dispatch => {
    CartService.emptyCart(id).then(cart => dispatch({ type: Constants.REMOVE_ALL_ITEMS }));
  };
};

export const updateQuantityForItem = (key, value) => {
  return (dispatch, getState) => {
    let currentCart = cloneDeep(getState().cart);
    currentCart = prepareData(currentCart, key, value);
    CartService.updateCart(currentCart).then(cart => {
      dispatch({
        type: Constants.UPDATE_ITEM_QUANTITY,
        cart,
      });
    });
  };
};

export const addFromSavedCarts = parts => {
  return (dispatch, getState) => {
    const cart = [];
    const cartID = getState().cart.id;
    asyncLoop(
      parts,
      (item, next) => {
        const temp = formatPartData(item, cart, cartID);

        /** if part has replacement find the other part and add it else just add the normal parts */
        if (item.spare_part.replacement !== null) {
          PartsService.findSpecificPart(item.spare_part.replacement)
            .then(foundPart => {
              temp.spare_part_id = foundPart.id;
              PartsService.addPartToCart(temp)
                .then(result => {
                  cart.push(result);
                  next();
                })
                .catch(err => next());
            })
            .catch(err => next());
        } else {
          PartsService.addPartToCart(temp)
            .then(result => {
              cart.push(result);
              next();
            })
            .catch(err => next());
        }
      },
      () => {
        dispatch({ type: Constants.ADD_FROM_SAVED_CARTS, cart });
      }
    );
  };
};

/** prepare data for post request */
const formatPartData = (item, cart, cartID) => {
  let temp = {
    spare_part_id: item.spare_part.id,
    quantity: item.quantity,
  };
  if (cartID !== null && typeof temp.cart_id === 'undefined') {
    temp.cart_id = cartID;
  } else if (cart[0] && cart[0].id && typeof temp.cart_id === 'undefined') {
    temp.cart_id = cart[0].id;
  }

  return temp;
};

export const emailCart = (email, id, history) => {
  return dispatch => {
    if (emailValidation(email)) {
      CartService.emailCart(email, id)
        .then(response => {
          dispatch({ type: Constants.SUCCESS_SENT_CART_EMAIL });
          history.push('/cart/success');
        })
        .catch(response => dispatch({ type: Constants.FAIL_SENT_CART_EMAIL }));
    } else {
      dispatch({ type: Constants.INVALID_EMAIL_SENT_ON_CART });
    }
  };
};

export const sendCart = (id, history) => {
  return dispatch => {
    CartService.sendCurrentCart(id).then(response => {
      // history.push('/cart/sent');
      window.location.href = '/cart/sent';
    });
  };
};

export const sendCartUpdate = cart => {
  cart = formatBeforeUpdate(cart);
  return dispatch => {
    /** send request to save cart */
    CartService.saveCartTemplate({ cart_id: cart.id, cart_name: cart.name }).then(response => {
      let tempCart = null;
      /** create new cart with all parts from saved cart */
      asyncLoop(
        response.cartitem_set,
        (item, next) => {
          let temp = {
            spare_part_id: item.spare_part,
            quantity: item.quantity,
          };
          if (tempCart && tempCart.id && typeof temp.cart_id === 'undefined') {
            temp.cart_id = tempCart.id;
          }
          PartsService.addPartToCart(temp).then(result => {
            tempCart = result;
            next();
          });
        },
        () => {
          dispatch(updateCartAfterSave(tempCart));
        }
      );
    });
  };
};
