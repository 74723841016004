import React from 'react';
import styled from 'styled-components';
import { Save, History } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Container from '../../../common/components/Container';
import Loader from '../../../common/components/Loader';

const ContainerList = styled.div`
  width: 100%;
`;
const ListItem = styled.div`
  padding: 5px 15px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h1`
  font-size: 30px;
  color: rgba(43, 61, 80, 0.8);
  font-weight: 600;
  margin: 0 0 10px 0;
`;
const Subtitle = styled.p`
  font-size: 18px;
  color: #2b3d50;
  font-weight: 300;
  margin: 0 0 30px 0;
`;
const NumberCircle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  background-color: rgba(43, 61, 80, 0.1);
  padding: 5px;
  width: 15px;
  height: 15px;
  text-align: center;
  color: rgba(43, 61, 80, 0.8);
  border-radius: 100px;
`;
const ErrorText = styled.p`
  color: #db3832;
`;
const MissingText = styled.p`
  color: rgba(43, 61, 80, 0.7);
  font-size: 16px;
`;

const SaveIconStyles = {
  verticalAlign: 'bottom',
  fontSize: '16px',
  marginRight: '15px',
};

const backURL = (url, currentTab, tab) => {
  url = url + `?${currentTab === 'savedcarts' ? 'Cart templates' : 'Cart requests'}=/${currentTab}`;
  return url;
};

function prepareDate(date) {
  const string = moment(date).toISOString();
  const newDate = moment(string).format('DD/MM/YYYY hh:mm:ss a');
  return newDate;
}
const CartList = ({ title, description, missing, loading, tab, error, list, url }) => (
  <Container alignItems="flex-start" maxWidth="560px">
    <Title>{title}</Title>
    <Subtitle>{description}</Subtitle>
    <ContainerList>
      {list.length === 0 && error == null && !loading ? <MissingText>{missing}</MissingText> : ''}
      {error ? (
        <ErrorText>
          There was an error loading this page. Please try again in a few minutes. If the problem
          persists please contact us at <b>desparts@desma.de</b>
        </ErrorText>
      ) : (
        ''
      )}
      {loading ? (
        <Loader />
      ) : (
        list.map(item => (
          <Link to={backURL(`${url}/${item.id}`, tab)} key={item.id}>
            <ListItem>
              <p>
                {tab === 'savedcarts' ? (
                  <Save style={SaveIconStyles} />
                ) : (
                  <History style={SaveIconStyles} />
                )}
                {item.name || `Cart request from ${prepareDate(item.created)}`}
              </p>
              <NumberCircle>{item.cartitem_set.length}</NumberCircle>
            </ListItem>
          </Link>
        ))
      )}
    </ContainerList>
  </Container>
);
export default CartList;
