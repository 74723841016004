import React from 'react';
import { Star, StarBorder } from '@material-ui/icons';

let UnactiveIcon = {
  color: '#556473',
  fontSize: '32px',
  cursor: 'pointer',
};

let ActiveIcon = {
  color: '#f1b042',
  fontSize: '32px',
  cursor: 'pointer',
};

const StarButton = ({ onSubmit, value }) => (
  <React.Fragment>
    {value ? (
      <Star onClick={onSubmit} style={ActiveIcon} />
    ) : (
      <StarBorder onClick={onSubmit} style={UnactiveIcon} />
    )}
  </React.Fragment>
);

export default StarButton;
