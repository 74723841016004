import * as Constants from '../constants/constants';

export const loginSuccess = token => {
  return dispatch => {
    dispatch({
      type: Constants.SET_USER_TOKEN,
      token,
    });
  };
};

export const setTerms = user => {
  return dispatch => {
    dispatch({ type: Constants.SET_USER_TERMS, user });
  };
};

// export const setTerms = value => {
//   return dispatch => {
//     dispatch({ type: Constants.SET_USER_TERMS, value });
//   };
// };

export const setCurrentUser = user => {
  return dispatch => {
    dispatch({ type: Constants.SET_CURRENT_USER, user });
  };
};

export const setLoading = loading => {
  return dispatch => {
    dispatch({ type: Constants.SET_LOADING, loading });
  };
};
