import React from 'react';
import HeaderContainer from '../common/containers/headerContainer';
import CartContainer from '../modules/cart/containers/cart';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

const CartPage = props => {
  checkTokenIsStillValid();

  return (
    <React.Fragment>
      <div id="react-no-print">
        <HeaderContainer tab="cart" />
      </div>
      <CartContainer history={props.history} />
    </React.Fragment>
  );
};

export default CartPage;
