import React from 'react';
import styled from 'styled-components';

const ImgContainerBig = styled.div`
  width: 160px;
  height: 160px;
  overflow: hidden;
  position: relative;
  margin-right: 20px;
  opacity: ${props => (props.alpha ? props.alpha : '')};
  @media (max-width: 400px) {
    width: 80px;
    height: 80px;
  }
`;

const ImgContainerSmall = styled.div`
  min-width: 65px;
  width: 65px;
  height: 65px;
  overflow: hidden;
  position: relative;
  margin-right: 30px;
  opacity: ${props => (props.alpha ? props.alpha : '')};

  @media (max-width: 400px) {
    min-width: 50px;
    width: 50px;
    height: 50px;
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  margin: auto auto;
`;

const ItemImg = ({ placeHolderStyle, alpha, src }) => (
  <React.Fragment>
    {placeHolderStyle === 'small' ? (
      <ImgContainerSmall alpha={alpha}>
        <Img src={src} />
      </ImgContainerSmall>
    ) : (
      <ImgContainerBig alpha={alpha}>
        <Img src={src} />
      </ImgContainerBig>
    )}
  </React.Fragment>
);

export default ItemImg;
