import React from 'react';
import Modal from 'react-modal';
import Input from '../../../../common/components/Input';
import styled from 'styled-components';

const ModalTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin: 0 0 10px 0;
`;
const Button = styled.button`
  color: #5d9ad1;
  background: none;
  border: none;
  margin: 0 0 0 40px;
  padding: 0;
  text-transform: uppercase;
`;
const InputContainer = styled.div`
  margin: 20px 0;
  width: 100%;
`;
const ButtonContainer = styled.div`
  text-align: right;
`;
const customStyles = {
  content: {
    width: '415px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
  },
};

const SaveModal = ({
  error,
  inputName,
  title,
  autoFocus,
  label,
  closeModal,
  confirm,
  onChange,
  value,
}) => (
  <React.Fragment>
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={label}
      className="modal"
      overlayClassName="modalOverlay"
      ariaHideApp={false}
    >
      <div>
        <ModalTitle>{title}</ModalTitle>
        <InputContainer>
          <Input
            error=""
            handleOnChange={onChange}
            label={'Cart name'}
            name={inputName}
            autoFocus="autofocus"
            value={value !== null ? value : ''}
          />
        </InputContainer>
        <ButtonContainer>
          <Button onClick={closeModal}>Cancel</Button>
          <Button onClick={confirm}>Save</Button>
        </ButtonContainer>
      </div>
    </Modal>
  </React.Fragment>
);

export default SaveModal;
