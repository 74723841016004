import React from 'react';
import styled from 'styled-components';
import StarButton from '../../../common/components/StarButton';
import TotalPrice from '../../../common/components/TotalPrice';
import ItemPrice from '../../../common/components/ItemPrice';
import ItemQuantity from '../../../common/components/ItemQuantity';
import AddToCart from '../../../common/components/LargeButton';
import PartsImg from '../../../common/components/ItemImg';
import PartNoComponent from './detailsElements/partNoComponent';
import AvailabilityComponent from './detailsElements/availability';
import AddedToCartButton from './detailsElements/addedToCartButton';
import Container from '../../../common/components/Container';

const ContentDiv = styled.div`
  overflow: hidden;
  flex: 1 1 30%;
`;
const ContainerPartInfo = styled.div`
  width: 390px;
  display: flex;
  flex-flow: column nowrap;
  min-height: 150px;
  justify-content: space-between;
  @media (max-width: 599px) {
    width: 100%;
    margin-top: 20px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 400px) {
    margin-bottom: 10px;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const ItemTitle = styled.h3`
  color: rgba(43, 61, 80, 0.8);
  font-size: 30px;
  margin: 0;

  @media (max-width: 400px) {
    font-size: 24px;
  }
`;
const Replaced = styled.p`
  color: #eb5757;
  font-size: 16px;
  line-height: 19px;
`;
const NumberContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 400px) {
    justify-content: flex-start;
  }
`;

const details = ({ item, addToCart, addMore, onChange, addToFavorites }) => (
  <React.Fragment>
    <Container padding="0" alignItems="flex-start" justifyContent="flex-start" flexFlow="row wrap">
      <PartsImg src={item.image_large || item.image} alpha={item.replacement ? '.5' : ''} />
      <ContainerPartInfo>
        <TitleContainer>
          <ItemTitle>{item.name}</ItemTitle>
          {item.replacement ? (
            <StarButton />
          ) : (
            <StarButton value={item.favorite} onSubmit={() => addToFavorites(item)} />
          )}
        </TitleContainer>
        {item.replacement ? (
          <Replaced>{`This article was replaced with Article No.${item.replacement}`}</Replaced>
        ) : (
          <PriceContainer>
            <ContentDiv>
              <ItemQuantity value={item.quantity} handleOnChange={onChange} label={'Quantity'} />
            </ContentDiv>
            <ContentDiv>
              {/* <ItemPrice {window.location.href = '/item/126' ? item.available = '0' : item.available = '2'} currency={item.currency} value={item.price} /> */}
              <ItemPrice available={item.available} currency={item.currency} value={item.price} />
            </ContentDiv>
            <ContentDiv>
              <TotalPrice
                available={item.in_stock}
                currency={item.currency}
                value={item.quantity * item.price}
                label="Total"
              />
            </ContentDiv>
          </PriceContainer>
        )}
        <ButtonsContainer>
          {item.toCart.add_more === false ? (
            <AddedToCartButton onSubmit={addMore} total={item.toCart.added_total} />
          ) : (
            <React.Fragment>
              {item.replacement ? (
                <AddToCart
                  onSubmit={() => (window.location.href = `/item/${item.replacement}`)}
                  value="Go to the new article page"
                />
              ) : (
                <AddToCart
                  onSubmit={item.quantity > 0 ? () => addToCart(item) : null}
                  value="Add to cart"
                  color={item.quantity > 0 ? '' : 'rgba(43, 61, 80, 0.4);'}
                  background={item.quantity > 0 ? '' : '#F5F5F5;'}
                  border={item.quantity > 0 ? '' : '1px solid #d7d9dc;'}
                />
              )}
            </React.Fragment>
          )}
        </ButtonsContainer>
      </ContainerPartInfo>
    </Container>
    <NumberContainer>
      <PartNoComponent weight={item.weight} newValue={item.item_no} oldValue={item.item_no_old} />
      <AvailabilityComponent
        replacement={item.replacement}
        value={item.units_in_stock}
        isInStock={item.in_stock}
        alpha={item.replacement ? '.5' : ''}
      />
    </NumberContainer>
  </React.Fragment>
);

export default details;
