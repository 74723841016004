import React from 'react';
import styled from 'styled-components';
import SelectRole from './adminCreateElements/selectRole';
import Button from '../../../common/components/LargeButton';
import Input from '../../../common/components/Input';
import Container from '../../../common/components/Container';
import SecondaryButton from '../../../common/components/SmallButton';

const Title = styled.h1`
  color: rgba(43, 61, 80, 0.8);
  font-size: 30px;
  margin: 0 0 20px 0;
`;
const Note = styled.p`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
  margin: 5px 0 0 0;
`;
const ErrorMessage = styled.p`
  font-size: 13px;
  color: #eb5757;
  margin-top: 5px;
`;

const isAddedInput = data =>
  data.userName.length > 0 ||
  data.email.length > 0 ||
  data.role.length > 0 ||
  data.password.length > 0 ||
  data.passwordRepeat.length > 0 ||
  data.customerNumber.length > 0;

const AdminCreate = ({ details, handleOnChange, onSubmit, onSelectChange, history }) => (
  <Container alignItems="flex-start">
    <Title>Create new account</Title>
    <Input
      error=""
      label="Username"
      name="userName"
      handleOnChange={handleOnChange}
      background="transparent"
      value={details.userName ? details.userName : ''}
      placeholder="Input username"
    />
    {details.error && details.error.field === 'userName' ? (
      <ErrorMessage>{details.error.msg}</ErrorMessage>
    ) : (
      <Note>Please don’t enter your real name.</Note>
    )}
    <Input
      error=""
      label="E-mail"
      name="email"
      handleOnChange={handleOnChange}
      commonMargin="20px 0 0 0"
      background="transparent"
      value={details.email ? details.email : ''}
      placeholder="Input e-mail"
    />
    {details.error && details.error.field === 'email' ? (
      <ErrorMessage>{details.error.msg}</ErrorMessage>
    ) : (
      ''
    )}
    <SelectRole label="Role" role={details.role} onSelectChange={onSelectChange} />
    {details.error && details.error.field === 'role' ? (
      <ErrorMessage>{details.error.msg}</ErrorMessage>
    ) : (
      ''
    )}
    {details.role.length !== 0 && details.role === 'user' ? (
      <React.Fragment>
        <Input
          error=""
          label="Customer No."
          name="customerNumber"
          handleOnChange={handleOnChange}
          background="transparent"
          value={details.customerNumber ? details.customerNumber : ''}
          placeholder="Input No."
          commonMargin="10px 0 0 0"
        />
        {details.error && details.error.field === 'customerNumber' ? (
          <ErrorMessage>{details.error.msg}</ErrorMessage>
        ) : (
          ''
        )}
      </React.Fragment>
    ) : (
      ''
    )}
    {details.error && typeof details.error.msg === 'undefined' ? (
      <ErrorMessage>{details.error}</ErrorMessage>
    ) : (
      ''
    )}
    {isAddedInput(details) ? (
      <Button
        color="white"
        onSubmit={() => onSubmit(details, history)}
        value="Create account"
        margin="20px 0 10px 0"
      />
    ) : (
      <Button
        color="rgba(43, 61, 80, 0.4);"
        border="1px solid rgba(43, 61, 80, 0.15);"
        margin="20px 0 10px 0"
        background="#F5F5F5;"
        value="Create account"
      />
    )}
    <SecondaryButton
      onSubmit={() => {
        window.location.href = '/admin';
      }}
      value="Cancel and back"
    />
  </Container>
);

export default AdminCreate;
