/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PrintTemplate from 'react-print';
import styled from 'styled-components';
import { LocationOn, Phone, Mail } from '@material-ui/icons';
import ImgComponent from '../../../../common/components/ItemImg';
import ItemQuantity from '../../../../common/components/ItemQuantity';
import TotalPrice from '../../../../common/components/TotalPrice';
import Logo from '../../../../common/components/Logo';
import config from '../../../../core/config/config.json';

const ContainerItem = styled.div`
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;
const Div = styled.div`
  display: flex;
  flex: 1 1 60%;
`;
const ContentDiv = styled.div`
  overflow: hidden;
  flex: 1 1 20%;
`;
const ContentPrice = styled.div`
  overflow: hidden;
  flex: 1 1 20%;
  text-align: right;
`;
const ItemDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
`;
const ItemTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #2d9cdb;
  margin: 0;
  a {
    color: #2d9cdb;
  }
`;
const ItemNr = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: rgba(43, 61, 80, 0.8);
  margin: 5px 0 0 0;
`;
const InStock = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #219653;
  margin: 10px 0 0 0;
`;
const PrintTitle = styled.h1`
  color: rgba(43, 61, 80, 0.8);
  font-size: 30px;
`;
const ItemSubtitle = styled.div`
  font-size: 13px;
  flex: 1 1 60%;
  text-align: left;
  font-weight: 600;
`;
const QuantitySubtitle = styled.div`
  font-size: 13px;
  flex: 1 1 20%;
  text-align: left;
  font-weight: 600;
`;
const SubtotalSubtitle = styled.div`
  font-size: 13px;
  flex: 1 1 20%;
  text-align: right;
  font-weight: 600;
`;
const SubtitleCont = styled.div`
  min-height: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
const TotalBlock = styled.div`
  min-height: 55px;
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
`;
const TotalCont = styled.div`
  margin: 15px 0;
  text-align: right;
`;
const GrandTotalLabel = styled.label`
  color: #000;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  text-align: right;
`;
const GrandTotal = styled.p`
  color: rgba(43, 61, 80, 0.8);
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0 0 0;
  text-align: right;
`;

const TablePrint = styled.table`
  width: 100%;
`;
const HeaderPrint = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;
const FooterPrint = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  & > div > p {
    margin: 0 0 5px 0;
  }
`;
const CurrentTime = styled.p`
  font-size: 13px;
  color: rgba(43, 61, 80, 0.8);
  margin: 0 0 40px 0;
`;
const iconStyles = {
  fontSize: '15px',
  color: '#2B3D50',
  verticalAlign: 'middle',
  margin: '0 10px 0 0',
};

const PrintCart = (props, hasUnderline) => (
  <PrintTemplate>
    <TablePrint>
      <thead>
        <HeaderPrint>
          <div>
            <CurrentTime>
              {new Date().toLocaleString('en-GB', { hour12: true })} <br /> {props.userEmail}
            </CurrentTime>
            <PrintTitle>{props.cart.name || 'Cart Current'}</PrintTitle>
          </div>
          <Logo />
        </HeaderPrint>
      </thead>
      <tbody>
        <SubtitleCont>
          <ItemSubtitle>
            <span>Item</span>
          </ItemSubtitle>
          <QuantitySubtitle>
            <span>Quantity</span>
          </QuantitySubtitle>
          <SubtotalSubtitle>
            <span>Subtotal</span>
          </SubtotalSubtitle>
        </SubtitleCont>
        {props.cart.cartitem_set.map((item, key) => (
          <ContainerItem key={key}>
            <Div>
              <ImgComponent
                src={item.spare_part.image ? item.spare_part.image : config.missing_image}
                placeHolderStyle="small"
              />
              <ItemDetails>
                <ItemTitle>
                  <a>{item.spare_part.name}</a>
                </ItemTitle>
                <ItemNr>{`${item.spare_part.item_no}/${item.spare_part.item_no_old}`}</ItemNr>
                <InStock>In stock</InStock>
              </ItemDetails>
            </Div>
            <ContentDiv>
              <ItemQuantity
                handleOnChange={() => null}
                noUnderline={hasUnderline}
                value={item.quantity}
              />
            </ContentDiv>
            <ContentPrice>
              <TotalPrice
                available={item.spare_part.in_stock}
                value={item.spare_part.price * item.quantity}
                currency={config.currency}
              />
            </ContentPrice>
          </ContainerItem>
        ))}
        <TotalBlock>
          <TotalCont>
            <GrandTotalLabel>Grand total</GrandTotalLabel>
            <GrandTotal>{`${props.grandTotal} ${config.currency}`}</GrandTotal>
          </TotalCont>
        </TotalBlock>
      </tbody>
      <tfoot className="footer">
        <FooterPrint>
          <div>
            <p>DESMA Schuhmaschinen GmbH</p>
            <p>Handelsregister Nr.: DE811628196</p>
          </div>
          <div>
            <p>
              <LocationOn style={iconStyles} />
              Desmastraße 3–5, 28832 Achim
            </p>
            <p>
              <Mail style={iconStyles} />
              desparts@desma.de
            </p>
            <p>
              <Phone style={iconStyles} />
              04202 9900
            </p>
          </div>
        </FooterPrint>
      </tfoot>
    </TablePrint>
  </PrintTemplate>
);

export default PrintCart;
