import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducer from './core/reducers';

const middlewares = [];
if (process.env.NODE_ENV !== 'production') {
  const { createLogger } = require('redux-logger'); // eslint-disable-line
  const logger = createLogger({ duration: true, collapsed: true });
  middlewares.push(logger);
}
/** add thunk */
middlewares.push(thunk);

// eslint-disable-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

export default createStore(reducer, enhancer);
