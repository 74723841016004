import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Logo from '../../../common/components/Logo';
import Button from '../../../common/components/LargeButton';
import UnactiveButton from '../../../common/components/SmallButton';
import Container from '../../../common/components/Container';
import { setTerms } from '../actions/login';
import { deleteCookie } from '../../../core/cookie/cookie';
import AdminService from '../../admin/services/admin';

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h1`
  font-size: 18px;
  font-weight: 300;
  margin: 20px 0;
`;
const Text = styled.div`
  font-family: 'Exo 2', sans-serif;
  width: 100%;
  height: 500px;
  font-size: 14px;
  line-height: 21px;
  padding: 10px 20px;
  resize: none;
  margin: 20px 0;
  outline: none;
  border: none;
  background: rgba(43, 61, 80, 0.05);
  overflow-y: scroll;
`;
const ErrorText = styled.p`
  color: #eb5757;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 0 0;
  text-align: left;
  width: 100%;
`;

class Terms extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = { error: undefined, loadingTerms: true, text: undefined };
    this.accept = this.accept.bind(this);
    this.decline = this.decline.bind(this);
    this.onEnter = this.onEnter.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keypress', this.onEnter, false);
    fetch(`/api/terms`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(data =>
        this.setState({
          loadingTerms: false,
          date: new Date(data.date),
          text: data.content.en,
        })
      )
      .catch(error => {
        this.setState({ error: error.message });
      });
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.onEnter, false);
  }

  onEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.accept();
    }
  }

  setUserData(termsValue) {
    const { currentUser } = this.props;
    return currentUser
      ? {
          user: {
            id: currentUser.id,
            accepted_terms_and_privacy_policy: termsValue,
          },
        }
      : null;
  }

  accept() {
    const user = this.setUserData(true);
    if (user) {
      AdminService.updateUser(user).then(result => {
        this.props.setTerms(result);
        this.props.history.push('/');
      });
    }
  }

  decline() {
    const user = this.setUserData(false);
    if (user) {
      AdminService.updateUser(user).then(result => {
        deleteCookie();
        window.location.href = '/';
      });
    }
  }

  render() {
    return (
      <Container>
        <Logo />
        <Title>Terms of service and privacy policy</Title>
        {this.state.error ? <ErrorText>{this.state.error}</ErrorText> : ''}
        {this.state.date && <p>Issued at: {this.state.date.toLocaleString()}</p>}
        {this.state.text && <Text dangerouslySetInnerHTML={{ __html: this.state.text }} />}
        <ButtonsContainer>
          <UnactiveButton onSubmit={this.decline} value="I disagree" margin="0 10px 0 0" />
          <Button onSubmit={this.accept} value="I agree" margin="0 0 0 10px" />
        </ButtonsContainer>
      </Container>
    );
  }
}

export default connect(
  state => ({ currentUser: state.login.currentUser }),
  { setTerms }
)(Terms);
