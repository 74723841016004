import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LinkImg = styled(Link)``;
const ImgLogo = styled.img`
  margin: 0;
  height: 40px;
`;

const Logo = props => (
  <LinkImg to="/">
    <ImgLogo src="/images/LogoDesParts.svg" />
  </LinkImg>
);

export default Logo;
