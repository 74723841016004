/* eslint-disable no-return-assign */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { NotInterested, PowerSettingsNew, DeleteForever } from '@material-ui/icons';
import SelectRole from './adminDetailsElements/selectRole';
import ConfirmModal from '../../../common/components/ConfirmModal';
import IconButton from '../../../common/components/IconButton';
import Container from '../../../common/components/Container';

const TitleTexts = styled.div`
  margin: 0 0 30px 0;
`;
const Title = styled.h1`
  color: rgba(43, 61, 80, 0.8);
  font-size: 30px;
  margin: 0;
`;
const Status = styled.span`
  color: #eb5757;
  font-size: 13px;
`;
const InfoContainer = styled.div`
  width: 100%;
  flex: 1 1 30%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;
const InfoBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
`;
const InfoLabel = styled.span`
  font-size: 13px;
  color: #000000;
  margin-bottom: 10px;
  font-weight: 600;
`;
const InfoText = styled.span`
  font-size: 15px;
  color: rgba(43, 61, 80, 0.6);
  font-weight: 500;
`;
const ContainerActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  width: 100%;
`;
const ButtonsActionsBlock = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
`;
const ContainerList = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-flow: column nowrap;
  align-items: center;
`;
const ListItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
`;
const LastLogins = styled.p`
  font-size: 13px;
  color: #000;
  font-weight: 600;
`;
const LeftItems = styled.p`
  font-size: 18px;
  color: rgba(43, 61, 80, 0.8);

  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

const formatRole = (admin, user, member) => {
  if (admin) {
    return 'admin';
  }
  if (user) {
    return 'user';
  }
  if (member) {
    return 'member';
  }

  return undefined;
};

// eslint-disable-next-line no-return-assign
const AdminDetails = ({
  user,
  onSelectChange,
  activeChange,
  deleteUser,
  onModalChange,
  openActiveModal,
  openDeactiveModal,
  openDeleteModal,
}) => {
  const lastLoginSortedArray = Array.isArray(user.last_logins)
    ? user.last_logins.sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];

  return (
    <Container alignItems="flex-start">
      <TitleTexts>
        <Title>{user.fullname}</Title>
        {user.is_active === false ? <Status>Deactivated</Status> : ''}
        {user.deleted === true ? (window.location.href = '/') : ''}
      </TitleTexts>
      <InfoContainer>
        <InfoBlock>
          <InfoLabel>Sent orders</InfoLabel>
          <InfoText>{user.sent_carts}</InfoText>
        </InfoBlock>
        <InfoBlock>
          <InfoLabel>Cart template</InfoLabel>
          <InfoText>{user.saved_carts}</InfoText>
        </InfoBlock>
        <InfoBlock>
          <InfoLabel>Favorite articles</InfoLabel>
          <InfoText>{user.favorite_items}</InfoText>
        </InfoBlock>
      </InfoContainer>
      <InfoContainer>
        <InfoBlock>
          <InfoLabel>E-Mail</InfoLabel>
          <InfoText>{user.email}</InfoText>
        </InfoBlock>
        <InfoBlock>
          <InfoLabel>Customer number</InfoLabel>
          <InfoText>{user.customer_number}</InfoText>
        </InfoBlock>
        <InfoBlock />
      </InfoContainer>
      <ContainerActions>
        <SelectRole
          label="Role"
          role={formatRole(user.is_admin, user.is_user, user.is_member)}
          onSelectChange={onSelectChange}
        />
        <ButtonsActionsBlock>
          {user.is_active ? (
            <IconButton
              onClick={() => onModalChange('openDeactiveModal', true)}
              Icon={NotInterested}
              iconColor="#2B3D50"
              iconSize="16px"
              iconAlign="middle"
              iconMargin="0 10px"
              value="Deactivate user"
              color="#2B3D50"
              border="1px solid transparent"
              background="transparent"
              padding="0px"
            />
          ) : (
            <IconButton
              onClick={() => onModalChange('openActiveModal', true)}
              Icon={PowerSettingsNew}
              iconColor="#2D9CDB"
              iconSize="16px"
              iconAlign="middle"
              iconMargin="0 10px"
              value="Activate user"
              color="#2D9CDB"
              border="1px solid transparent"
              background="transparent"
              padding="0px"
            />
          )}
          <IconButton
            onClick={() => onModalChange('openDeleteModal', true)}
            Icon={DeleteForever}
            iconColor="#EB5757"
            iconSize="16px"
            iconAlign="middle"
            iconMargin="0 10px"
            value="Delete user"
            color="#EB5757"
            margin="0 0 0 30px"
            border="1px solid transparent"
            background="transparent"
            padding="0px"
          />
        </ButtonsActionsBlock>
      </ContainerActions>
      <LastLogins>Last logins</LastLogins>
      <ContainerList>
        {lastLoginSortedArray.length > 0 ? (
          lastLoginSortedArray.map(({ date, ip, location }) => (
            <ListItem>
              <LeftItems>
                {[moment(date).format('DD:MM:YYYY, HH:mm'), location, ip].join(' | ')}
              </LeftItems>
            </ListItem>
          ))
        ) : (
          <ListItem>
            <LeftItems>---</LeftItems>
          </ListItem>
        )}
      </ContainerList>
      {openDeactiveModal ? (
        <ConfirmModal
          title={`Do you really want to deactivate the user '${user.fullname}'?`}
          confirmText="Deactivate"
          confirm={() => activeChange(false)}
          closeModal={() => onModalChange('openDeactiveModal', false)}
        />
      ) : (
        ''
      )}
      {openActiveModal ? (
        <ConfirmModal
          title={`Do you really want to activate the user '${user.fullname}'?`}
          confirmText="Activate"
          confirm={() => activeChange(true)}
          closeModal={() => onModalChange('openActiveModal', false)}
        />
      ) : (
        ''
      )}
      {openDeleteModal ? (
        <ConfirmModal
          title={`Do you really want to delete the user account '${user.fullname}'?`}
          confirmText="Delete"
          confirm={deleteUser}
          closeModal={() => onModalChange('openDeleteModal', false)}
          btnColor="#EB5757"
          text="This action CANNOT be undone"
          SubtextColor="#EB5757"
        />
      ) : (
        ''
      )}
    </Container>
  );
};

export default AdminDetails;
