import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ImgComponent from '../../../../common/components/ItemImg';
import config from '../../../../core/config/config.json';

const ListItem = styled.div`
  background: #fff;
  width: 100%;
  height: 75px;
  overflow: hidden;
  display: flex;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 3px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px
  position: relative;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  &:hover{
    background: #ecf5fa;
    border: 1px solid #2D9CDB;
  }
`;
const Div = styled.div`
  display: flex;
  flex: 1 1 60%;
`;
const LinkConn = styled(Link)`
  width: 100%;
`;
const ItemDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
`;
const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #2d9cdb;
  margin: 0;
`;
const ItemNr = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: rgba(43, 61, 80, 0.8);
  margin: 0;
`;
const NotInStock = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: rgb(235, 87, 87);
  margin: 10px 0 0 0;
`;
const InStock = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: #219653;
  margin: 10px 0 0 0;
`;
const MissingText = styled.p`
  color: rgba(43, 61, 80, 0.7);
  font-size: 16px;
`;

function Availability({ isInStock, units }) {
  if (!isInStock) {
    return <NotInStock>Not in stock</NotInStock>;
  }

  if (isInStock && units) {
    return <InStock>{units} in stock</InStock>;
  }

  if (isInStock && !units) {
    return <InStock>In stock</InStock>;
  }

  return null;
}

const PartsListElement = ({ list }) => (
  <React.Fragment>
    {list.length === 0 ? (
      <MissingText>
        {' '}
        You have no favorites yet. Click on the star icon on an article’s page to mark it as
        favorite.{' '}
      </MissingText>
    ) : (
      ''
    )}
    {list.map((item, key) => (
      <LinkConn key={key} to={`/item/${item.item_no}`}>
        <ListItem key={item.id}>
          <Div>
            <ImgComponent
              src={item.image ? item.image : config.missing_image}
              placeHolderStyle="small"
            />
            <ItemDetails>
              <ItemTitle>{item.name}</ItemTitle>
              <ItemNr>
                {item.item_no_old ? `${item.item_no}/${item.item_no_old}` : item.item_no}
              </ItemNr>
              <Availability isInStock={item.in_stock} units={item.units_in_stock} />
            </ItemDetails>
          </Div>
        </ListItem>
      </LinkConn>
    ))}
  </React.Fragment>
);

export default PartsListElement;
