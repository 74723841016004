import React from 'react';
import { connect } from 'react-redux';
import { getCookie } from '../cookie/cookie';
import { loginSuccess, setCurrentUser, setLoading } from '../../modules/login/actions/login';
import AdminService from '../../modules/admin/services/admin';

/**
 * Gets the token from the cookie and checks whether the token is still valid.
 * If not, deletes the cookie and redirects to "/".
 *
 * @returns {string} If the token is OK, returns the token. Otherwise an empty string.
 */
export const checkTokenIsStillValid = () => {
  const token = getCookie();
  const tokenExpired = token === undefined;
  if (tokenExpired) {
    window.location.href = '/';
  }
};

class UserAuth extends React.Component {
  componentDidMount() {
    this.props.checkLogin();
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default connect(
  null,
  dispatch => ({
    checkLogin: () => {
      const token = getCookie();
      if (token) {
        AdminService.fetchSingleUser().then(user => {
          dispatch(loginSuccess(token));
          dispatch(setCurrentUser(user));
          dispatch(setLoading(false));
        });
      } else {
        dispatch(setLoading(false));
      }
    },
  })
)(UserAuth);
