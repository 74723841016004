import React from 'react';
import styled from 'styled-components';
import Button from '../../../../common/components/LargeButton';

const ContainerSearch = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;
const SearchInput = styled.input`
  background-color: #fff;
  border: 1px solid rgba(43, 61, 80, 0.2);
  border-radius: 3px;
  outline: none;
  width: 80%;
  height: auto;
  margin-right: 10px;
  padding: 10px;
`;

const search = ({ onSearch }) => (
  <ContainerSearch>
    <SearchInput onChange={onSearch} placeholder="Search in all..." />
    <Button
      value="Search"
      color="#2D9CDB"
      background="#fff"
      border="1px solid rgba(43,61,80,0.2);"
      width="20%"
    />
  </ContainerSearch>
);

export default search;
