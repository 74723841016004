import * as Cookies from 'js-cookie';
import * as jwt from 'jsonwebtoken';

const tokenCookieName = '_jwt';
const thrityMinutesInMs = 30 * 60 * 1000;

/**
 * Sets the value of the "_jwt" cookie to `token` and the `expires` attribute to the `exp` value of
 * the token.
 *
 * @param {string} token Base64 encoded json web token
 */
export const setCookie = token => {
  const payload = jwt.decode(token);

  let expires = Date.now() + thrityMinutesInMs;
  if (payload && payload.exp && typeof payload.exp === 'number') {
    const timestampWhenTokenExpiresInMs = payload.exp * 1000;
    expires = new Date(timestampWhenTokenExpiresInMs);
  }

  Cookies.set(tokenCookieName, token, { expires });
};

/**
 * @returns {(string|undefined)} `undefined` if token expired or not set, otherwise the jwt.
 */
export const getCookie = () => {
  const token = Cookies.get(tokenCookieName);
  return token;
};

export const deleteCookie = () => {
  Cookies.remove(tokenCookieName);
};
