import React, { useState } from 'react';
import styled from 'styled-components';
import { Save, Print, Mail, Check, RemoveShoppingCart } from '@material-ui/icons';
import ItemContainer from './cartElements/itemContainer';
import SendCart from '../../../common/components/LargeButton';
import SaveModal from './cartElements/SaveModal';
import ConfirmModal from '../../../common/components/ConfirmModal';
import SendModal from './cartElements/SendModal';
import IconButton from '../../../common/components/IconButton';
import PrintCart from './cartElements/printCart';
import Container from '../../../common/components/Container';
import config from '../../../core/config/config.json';

const TotalBlock = styled.div`
  min-height: 55px;
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 400px) {
    flex-flow: row wrap;
    justify-content: flex-end;
  }
`;
const TotalCont = styled.div`
  margin: 15px 0;
  text-align: right;
`;
const TotalText = styled.p`
  color: rgba(43, 61, 80, 0.7);
  font-size: 14px;
  text-align: left;
  line-height: 17px;
  width: 60%;

  @media (max-width: 400px) {
    width: 100%;
    order: 1;
  }
`;
const ItemSubtitle = styled.div`
  font-size: 13px;
  flex: 1 1 60%;
  text-align: left;
  font-weight: 600;
`;
const QuantitySubtitle = styled.div`
  font-size: 13px;
  flex: 1 1 20%;
  text-align: left;
  font-weight: 600;

  @media (max-width: 400px) {
    display: none;
  }
`;
const SubtotalSubtitle = styled.div`
  font-size: 13px;
  flex: 1 1 20%;
  text-align: right;
  font-weight: 600;

  @media (max-width: 400px) {
    display: none;
  }
`;
const SubtitleCont = styled.div`
  min-height: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
const TitleCont = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
const TitleButtons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 40%;
`;
const Title = styled.p`
  color: rgba(43, 61, 80, 0.8);
  font-size: 30px;
  font-weight: 600;
  flex: 1 1 60%;

  @media (max-width: 400px) {
    font-size: 24px;
  }
`;
const TotalLabel = styled.label`
  color: #000;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  text-align: right;
`;
const TotalPrice = styled.p`
  color: rgba(43, 61, 80, 0.8);
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0 0 0;
  text-align: right;
`;
const IconStyles = {
  fontSize: '16px',
  color: '#2D9CDB',
  marginLeft: '20px',
  cursor: 'pointer',
};

const InActiveIconStyles = {
  fontSize: '16px',
  color: '#2D9CDB',
  marginLeft: '20px',
  cursor: 'pointer',
  opacity: '.5',
};

const CartPage = ({
  cart,
  grandTotal,
  onSubmit,
  openPrint,
  onChange,
  history,
  onModalChange,
  onModalSubmit,
  onEmptyAll,
  onQuantityChange,
  removeFromCart,
  onSendEmailWithCart,
  userEmail,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  return (
    <React.Fragment>
      <PrintCart userEmail={userEmail} cart={cart} grandTotal={grandTotal} hasUnderline="none" />
      <div id="react-no-print">
        <Container margin="10px auto" justifyContent="space-between">
          <TitleCont>
            <Title>
              Cart current
              {cart.cartitem_set.length > 0 ? (
                <React.Fragment>
                  <Print onClick={openPrint} style={IconStyles} />
                  <Mail onClick={() => onModalChange('openSendModal', true)} style={IconStyles} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Print style={InActiveIconStyles} />
                  <Mail style={InActiveIconStyles} />
                </React.Fragment>
              )}
            </Title>
            <TitleButtons style={cart.cartitem_set.length === 0 ? { opacity: '.5' } : {}}>
              {cart.saved === false ? (
                <IconButton
                  onClick={() =>
                    cart.cartitem_set.length !== 0 ? onModalChange('openSaveModal', true) : null
                  }
                  Icon={Save}
                  iconColor="#2D9CDB"
                  iconSize="16px"
                  iconMargin="0 10px"
                  iconAlign="middle"
                  value="Save"
                  color="#2D9CDB"
                  border="1px solid transparent"
                  background="transparent"
                  padding="0px"
                />
              ) : (
                <IconButton
                  Icon={Check}
                  iconColor="#219653"
                  iconSize="16px"
                  iconMargin="0 10px"
                  iconAlign="middle"
                  value="Saved"
                  color="#219653"
                  border="1px solid transparent"
                  background="transparent"
                  padding="0px"
                />
              )}
              <IconButton
                onClick={() =>
                  cart.cartitem_set.length !== 0 ? onModalChange('openEmptyModal', true) : ''
                }
                Icon={RemoveShoppingCart}
                iconColor="#EB5757"
                iconSize="16px"
                iconMargin="0 10px"
                iconAlign="middle"
                value="Empty"
                color="#EB5757"
                margin="0 0 0 40px"
                border="1px solid transparent"
                background="transparent"
                padding="0px"
              />
            </TitleButtons>
          </TitleCont>
          {cart.cartitem_set.length === 0 ? (
            ''
          ) : (
            <SubtitleCont>
              <ItemSubtitle>
                <span>Item</span>
              </ItemSubtitle>
              <QuantitySubtitle>
                <span>Quantity</span>
              </QuantitySubtitle>
              <SubtotalSubtitle>
                <span>Subtotal</span>
              </SubtotalSubtitle>
            </SubtitleCont>
          )}
          {cart.cartitem_set.map((item, key) => (
            <React.Fragment key={key}>
              <ItemContainer
                cartID={cart.id}
                id={item.spare_part.id}
                openRemoveModal={cart.openRemoveModal}
                onModalChange={onModalChange}
                onQuantityChange={onQuantityChange}
                removeFromCart={removeFromCart}
                itemNo={item.spare_part.item_no}
                itemNoOldsystem={item.spare_part.item_no_old}
                name={item.spare_part.name}
                currency={config.currency}
                totalPrice={item.spare_part.price * item.quantity}
                quantity={item.quantity}
                index={key}
                src={item.spare_part.image}
                available
                unitsInStock={item.spare_part.units_in_stock}
                isInStock={item.spare_part.in_stock}
                showDeleteModal={() =>
                  setShowDeleteModal({ id: item.spare_part.id, name: item.spare_part.name })
                }
              />
            </React.Fragment>
          ))}
          {showDeleteModal ? (
            <ConfirmModal
              title={`Do you really want to remove the article "${
                showDeleteModal.name
              }" from your cart?`}
              confirmText="Remove"
              btnColor="#EB5757"
              confirm={() => {
                removeFromCart(cart.id, showDeleteModal.id);
                setShowDeleteModal(null);
              }}
              closeModal={() => setShowDeleteModal(null)}
            />
          ) : null}
          <TotalBlock>
            {cart.cartitem_set.length === 0 ? (
              <TotalText>You do not have any products in your cart yet.</TotalText>
            ) : (
              <TotalText>
                After choosing the action “Send request”, your cart will be sent to DESMA. An
                employee will contact you soon after to start an actual order.{' '}
              </TotalText>
            )}
            <TotalCont>
              <TotalLabel>Grand total</TotalLabel>
              <TotalPrice>{`${grandTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${config.currency}`}</TotalPrice>
            </TotalCont>
          </TotalBlock>
          {cart.cartitem_set.length === 0 ? (
            <SendCart
              color="rgba(43, 61, 80, 0.4);"
              border="1px solid rgba(43, 61, 80, 0.15);"
              background="#F5F5F5;"
              value="Send request"
            />
          ) : (
            <SendCart
              value="Send request"
              onSubmit={() => onModalChange('openSendCartModal', true)}
            />
          )}
          {cart.openSendCartModal ? (
            <ConfirmModal
              title="Do you really want to send your cart as a request to DESMA?"
              confirmText="SEND CART"
              confirm={() => onSubmit(cart, history)}
              closeModal={() => onModalChange('openSendCartModal', false)}
            />
          ) : (
            ''
          )}
          {cart.openSendModal ? (
            <SendModal
              title="Please enter an e-mail to send to:"
              inputName="email"
              description="cart name"
              error={cart.invalidEmailError}
              onChange={onChange}
              confirm={() => onSendEmailWithCart(cart.email, cart.id, history)}
              closeModal={() => onModalChange('openSendModal', false)}
            />
          ) : (
            ''
          )}
          {cart.openSaveModal ? (
            <SaveModal
              title="Name your cart"
              inputName="name"
              description="cart name"
              value={cart.name}
              onChange={onChange}
              label="Name your cart"
              confirm={() => onModalSubmit(cart)}
              closeModal={() => onModalChange('openSaveModal', false)}
            />
          ) : (
            ''
          )}
          {cart.openEmptyModal ? (
            <ConfirmModal
              title="Do you really want to empty your current cart?"
              confirmText="Empty"
              btnColor="#EB5757"
              confirm={() => onEmptyAll(cart.id)}
              closeModal={() => onModalChange('openEmptyModal', false)}
            />
          ) : (
            ''
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CartPage;
