import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BackToBtn from '../common/components/LargeButton';
import SuccessWithHeader from '../common/components/SuccessWithHeader';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

const TextTitle = styled.h3`
  color: rgba(43, 61, 80, 0.8);
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0;
  text-align: center;
  width: 90%;
`;
const TextBlock = styled.p`
  color: rgba(43, 61, 80, 0.8);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 40px;
  width: 90%;
`;

const buttonStyleActive = styled.button`
  color: #fff;
  width: 100%;
  background: #4c9bd6;
  border: 1px solid #478dc1;
  border-radius: 3px;
  padding: 10px;
`;

class AdminCreateSuccess extends React.Component {
  constructor(props, context) {
    super(props, context);

    checkTokenIsStillValid();

    this.state = {
      role: 'User',
    };
  }

  componentWillMount() {
    const { tempRole } = this.props;
    if (tempRole.length > 0) {
      this.setState({ role: tempRole.charAt(0).toUpperCase() + tempRole.slice(1) });
    }
  }

  render() {
    return (
      <SuccessWithHeader tab="admin">
        <TextTitle>{`${this.state.role} has been successfully created.`}</TextTitle>
        <TextBlock>{`${this.state.role} has been created.`}</TextBlock>
        <BackToBtn
          ButtonElement={buttonStyleActive}
          onSubmit={() => {
            window.location.href = '/admin';
          }}
          value="Back to user administration"
        />
      </SuccessWithHeader>
    );
  }
}

export default connect(state => ({ tempRole: state.adminCreate.temp_role }))(AdminCreateSuccess);
