import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import IntlProvider from './common/components/IntlProvider';
import ApplicationPage from './pages/ApplicationPage';

const App = ({ store }) => (
  <Provider store={store}>
    <IntlProvider>
      <BrowserRouter>
        <Route path="" component={ApplicationPage} />
      </BrowserRouter>
    </IntlProvider>
  </Provider>
);

export default App;
