import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  min-height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: ${props => (props.background ? props.background : '#fff')};
  position: absolute;
  width: 100%;
`;

const MainLayout = props => <Layout background={props.background}>{props.children}</Layout>;

export default MainLayout;
