import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Input from '../../../../common/components/Input';
import SendLinkButton from '../../../../common/components/LargeButton';
import CancelButton from '../../../../common/components/SmallButton';

const InputsContainer = styled.div`
  width: 100%;
  margin: 10px 0;
`;
const Text = styled.p`
  color: rgba(43, 61, 80, 0.5);
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 40px 0 30px 0;
`;
const ButtonsContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  height: 90px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  & * {
    width: 100%;
  }
`;
const ErrorText = styled.p`
  color: #eb5757;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  margin: 20px 0;
`;

const sentEmail = ({ handleChange, email, error, onSubmit, buttonStyleActive }) => (
  <React.Fragment>
    <Text>
      Please enter your DESparts e-mail address. We will send you a link for resetting your
      password.
    </Text>
    <ErrorText>{error ? error : ''}</ErrorText>
    <InputsContainer>
      <Input
        error={error}
        label="E-mail"
        placeholder="Input your e-mail here ..."
        value={email}
        name="email"
        handleOnChange={handleChange}
      />
    </InputsContainer>
    <ButtonsContainer>
      <SendLinkButton
        value="Send link"
        ButtonElement={buttonStyleActive}
        onSubmit={() => onSubmit(email)}
      />
      <Link to="/login">
        <CancelButton value="Cancel and back to login" />
      </Link>
    </ButtonsContainer>
  </React.Fragment>
);

export default sentEmail;
