import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  NOT_FOUND_PAGE_TITLE: {
    id: 'NotFound.NOT_FOUND_PAGE_TITLE',
    defaultMessage: 'Page not Found',
  },
  NOT_FOUND_PAGE_MESSAGE: {
    id: 'NotFound.NOT_FOUND_PAGE_MESSAGE',
    defaultMessage: ' We could not find the page you are looking for (Error 404).',
  },
  BACK_TO_START_PAGE: {
    id: 'NotFound.BACK_TO_START_PAGE',
    defaultMessage: 'Back to start page',
  },
});

export default function NotFound(props) {
  return (
    <div className="flex-auto flex justify-center items-center">
      <div className="br2 ba b--black-10 bg-white pa3 w6">
        <h3 className="mt0">
          <FormattedMessage {...messages.NOT_FOUND_PAGE_TITLE} />
        </h3>
        <p>
          <FormattedMessage {...messages.NOT_FOUND_PAGE_MESSAGE} />
        </p>
        <p>
          <code>{props.pathname}</code>
        </p>

        <Link className="db bg-primary ba white pa2 br2 link tc" to="/">
          <FormattedMessage {...messages.BACK_TO_START_PAGE} />
        </Link>
      </div>
    </div>
  );
}
