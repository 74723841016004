import { connect } from 'react-redux';
import HeaderComponent from '../components/Header';

const mapStateToProps = (state, props) => {
  return {
    tab: props.tab,
    totalItems: state.cart.cartitem_set.reduce((sum, cartItem) => sum + cartItem.quantity, 0),
    history: typeof props.history !== 'undefined' ? props.history : null,
    backPage: typeof props.backPage !== 'undefined' ? props.backPage : null,
    search: typeof props.search !== 'undefined' ? props.search : null,
  };
};

const HeaderContainer = connect(mapStateToProps)(HeaderComponent);

export default HeaderContainer;
