import React from 'react';
import styled from 'styled-components';
import ItemContainer from './favoritePartsElements/itemContainer';
import Container from '../../../common/components/Container';
import config from '../../../core/config/config.json';
import Loader from '../../../common/components/Loader';

const Title = styled.h1`
  font-size: 30px;
  color: rgba(43, 61, 80, 0.8);
  font-weight: 600;
  margin: 0 0 10px 0;
`;
const Subtitle = styled.p`
  font-size: 18px;
  color: #2b3d50;
  font-weight: 300;
  margin: 0 0 30px 0;
`;
const ErrorText = styled.p`
  color: #db3832;
  font-size: 16px;
`;
const MissingText = styled.p`
  color: rgba(43, 61, 80, 0.7);
  font-size: 16px;
`;

const FavoriteParts = ({ parts, error, loading }) => (
  <React.Fragment>
    <Container alignItems="flex-start" maxWidth="550px">
      <Title>Favorite articles</Title>
      <Subtitle>Those are the articles you’ve marked as favorite.</Subtitle>
      {parts.length === 0 && error == null ? (
        <MissingText>
          {' '}
          You have no favorites yet. Click on the star icon on an article's page to mark it as
          favorite.{' '}
        </MissingText>
      ) : (
        ''
      )}
      {loading ? <Loader /> : ''}
      {error ? (
        <ErrorText>
          There was an error loading this page. Please try again in a few minutes. If the problem
          persists please contact us at <b>desparts@desma.de</b>
        </ErrorText>
      ) : (
        ''
      )}
      {parts.map((item, key) => (
        <React.Fragment key={key}>
          <ItemContainer
            id={item.id}
            itemNo={item.item_no}
            itemNoOldsystem={item.item_no_old}
            itemInStock={item.in_stock}
            itemUnitsInStock={item.units_in_stock}
            name={item.name}
            index={key}
            src={item.image ? item.image : config.missing_image}
          />
        </React.Fragment>
      ))}
    </Container>
  </React.Fragment>
);

export default FavoriteParts;
