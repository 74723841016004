import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const Select = styled.select`
  color: rgba(43, 61, 80, 0.6);
  background: transparent;
  border: none;
  margin: 0 -5px;
  width: 100%;
  border-radius: 0px;
  &:focus {
    outline: none;
  }
`;
const Hr = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(43, 61, 80, 0.1);
  margin: 5px 0;
  padding: 0;
  width: 100%;
`;
const Label = styled.label`
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
`;

const SelectRole = ({ role, label, onSelectChange }) => (
  <InputContainer>
    <Label>{label}</Label>
    <Select onChange={onSelectChange} value={role}>
      <option value="">Choose a role...</option>
      <option value="user">User</option>
      <option value="admin">Admin</option>
      <option value="member">Member</option>
    </Select>
    <Hr />
  </InputContainer>
);

export default SelectRole;
