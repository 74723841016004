export const GET_CART = 'GET_CART';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const TOGGLE_CART_MODAL = 'TOGGLE_CART_MODAL';
export const UPDATE_CART_VALUE = 'UPDATE_CART_VALUE';
export const UPDATE_CART_VALUE_AFTER_SAVE = 'UPDATE_CART_VALUE_AFTER_SAVE';
export const REMOVE_ALL_ITEMS = 'REMOVE_ALL_ITEMS';
export const UPDATE_ITEM_QUANTITY = 'UPDATE_ITEM_QUANTITY';
export const ADD_FROM_SAVED_CARTS = 'ADD_FROM_SAVED_CARTS';
export const SUCCESS_SENT_CART_EMAIL = 'SUCCESS_SENT_CART_EMAIL';
export const FAIL_SENT_CART_EMAIL = 'FAIL_SENT_CART_EMAIL';
export const INVALID_EMAIL_SENT_ON_CART = 'INVALID_EMAIL_SENT_ON_CART';
