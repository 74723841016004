import React from 'react';
import styled from 'styled-components';
const Label = styled.p.attrs({
  style: props => ({
    color: props.color,
  }),
})`
  color: ${props => textColor(props.value, props.error)};
  text-align: ${props => (props.align ? props.align : 'left')};
  font-size: ${props => (props.size ? props.size + 'px' : '16px')};
  font-weight: 600;
  margin: 10px 0;
`;
const InputContainer = styled.div`
  width: ${props => (props.blockWidth ? props.blockWidth : '100%')};
  display: flex;
  flex-flow: ${props => (props.flexAlign ? props.flexAlign : 'column-reverse nowrap')};
  align-items: left;
  margin: ${props => (props.commonMargin ? props.commonMargin : '0')};
`;
const InputBlock = styled.input`
  outline: none;
  font-family: 'Exo 2', sans-serif;
  background-color: ${props => (props.background ? props.background : '#fff')};
  margin: ${props => (props.margin ? props.margin : '0px')};
  border-radius: ${props => (props.radius ? props.radius : '0px')};
  color: rgba(43, 61, 80, 0.6);
  text-align: left;
  width: ${props => (props.width ? props.width : '100%')};
  font-size: 15px;
  border: ${props => (props.border ? props.border : 'none')};
  padding: ${props => (props.padding ? props.padding : '0 0 10px 0')};
  border-bottom: ${props => (props.border ? ' ' : '1px solid rgba(43, 61, 80, 0.1)')};
  &:focus {
    border: ${props => (props.border ? '1px solid #5d9ad1' : '')};
    border-bottom: 1px solid #5d9ad1 !important;
    outline: none;
  }
  &:focus ~ ${Label} {
    color: #5d9ad1 !important;
    outline: none;
  }
`;

const textColor = (value, error) => {
  switch (true) {
    case value !== 0 && error === null:
      return '#5d9ad1';
    case error !== null:
      return '#EB5757';
    default:
      return 'rgba(0, 0, 0, 0.38)';
  }
};

const Input = ({
  blockWidth,
  flexAlign,
  autoFocus,
  type,
  margin,
  label,
  value,
  name,
  error,
  background,
  padding,
  radius,
  border,
  commonMargin,
  width,
  placeholder,
  handleOnChange,
}) => (
  <InputContainer flexAlign={flexAlign} blockWidth={blockWidth} commonMargin={commonMargin}>
    {/* <Dynamic error={error} value={value} size='50' align='right'> this is test </Dynamic> */}
    <InputBlock
      autoFocus={autoFocus}
      type={type}
      margin={margin}
      width={width}
      border={border}
      placeholder={placeholder}
      radius={radius}
      padding={padding}
      style={error ? { borderBottom: '1px solid #EB5757' } : {}}
      onChange={handleOnChange}
      name={name}
      value={value !== null ? value : ''}
      background={background}
    />
    {typeof label !== 'undefined' ? (
      <Label
        error={error}
        value={value}
        size="13"
        style={error || typeof error === 'undefined' ? { color: '#EB5757' } : { color: '#000' }}
      >
        {label}
      </Label>
    ) : (
      ''
    )}
  </InputContainer>
);

export default Input;
