import React from 'react';
import IconButton from '../../../common/components/IconButton';
import Container from '../../../common/components/Container';
import SelectElement from './adminListElements/select';
import SearchElement from './adminListElements/search';
import styled from 'styled-components';
import { Edit, AccountCircle, PersonAdd } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const Title = styled.h1`
  color: rgba(43, 61, 80, 0.8);
  font-size: 30px;
  margin: 0 0 30px 0;
`;
const ContainerTabs = styled.div`
  margin: 20px 0;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-items: center;
  cursor: pointer;
`;
const ListItem = styled.div`
  background: #fff;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: rgba(45, 156, 219, 0.1);
    border: 1px solid #2d9cdb;
  }
`;
const ItemSide = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;
const AccountIconStyle = {
  margin: '0 15px',
  color: '#2B3D50',
  fontSize: '16px',
};
const PenIconStyle = {
  margin: '0 15px',
  color: '#2D9CDB',
  fontSize: '16px',
};
const LinkTo = styled(Link)`
  width: 100%;
`;
const formatRole = (admin, member, user) => {
  if (admin) {
    return 'Admin';
  }
  if (member) {
    return 'Member';
  }
  if (user) {
    return 'User';
  }
};

const AdminList = ({ background, selected, selectChoice, onSelectValue, array, onSearch }) => (
  <Container alignItems="flex-start">
    <Title>User administration</Title>
    <IconButton
      onClick={() => (window.location.href = 'admin/usercreate')}
      iconColor="#fff"
      iconSize="16px"
      iconMargin="0 10px"
      iconAlign="middle"
      border="none"
      width="100%"
      value="Create new account"
      Icon={PersonAdd}
    />
    <ContainerTabs>
      <SelectElement onSelect={onSelectValue} selected={selected} array={selectChoice} />
    </ContainerTabs>
    <SearchElement onSearch={onSearch} />
    <Container justifyContent="flex-start" padding="0">
      {array
        ? array.map((item, key) => {
            return (
              <LinkTo key={key} to={`/admin/account/${item.id}?Administration=/admin`}>
                <ListItem>
                  <ItemSide>
                    <AccountCircle style={AccountIconStyle} />
                    <p>{item.fullname}</p>
                  </ItemSide>
                  <ItemSide>
                    {formatRole(item.is_admin, item.is_member, item.is_user)}
                    <Edit style={PenIconStyle} />
                  </ItemSide>
                </ListItem>
              </LinkTo>
            );
          })
        : ''}
    </Container>
  </Container>
);

export default AdminList;
