import * as Constants from '../constants/constants';
import { validateData, prepareData } from '../logic/adminCreate';
import AdminService from '../services/admin';

export const adminUpdate = (field, value) => {
  return dispatch => {
    dispatch({
      type: Constants.ON_ADMIN_CREATE_UPDATE,
      field,
      value,
    });
  };
};

const handleError = respBody => {
  const field = Object.keys(respBody)[0];
  const msg = field === 'email' ? 'This email address is already used!' : 'Unkown error';

  return {
    type: Constants.ON_ADMIN_CREATE_SUBMIT_ERROR,
    error: { field, msg },
  };
};

export const submitAdminCreate = (data, history) => {
  return dispatch => {
    const isValid = validateData(data);
    if (isValid !== true) {
      dispatch(handleError(isValid));
    } else {
      const newData = prepareData(data);
      AdminService.createUser(newData)
        .then(result => {
          dispatch({ type: Constants.ON_ADMIN_CREATE_SUBMIT_SUCCESS });
          const { id } = result;
          history.push(`/admin/account/${id}`);
        })
        .catch(err => {
          dispatch(handleError(err.response.body));
        });
    }
  };
};
