import React from 'react';
import ForgottenPasswordComponent from '../modules/login/components/forgottenPass';

const ForgotPasswordPage = () => (
  <React.Fragment>
    <ForgottenPasswordComponent />
  </React.Fragment>
);

export default ForgotPasswordPage;
