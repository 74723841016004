const error = { msg: 'This field is empty', field: 'customerNumber' };
const emailError = { msg: 'This is invalid email', field: 'email' };
const userNameError = { msg: 'This is invalid username', field: 'userName' };
const roleError = { msg: 'This is invalid role', field: 'role' };

const validateEmail = email => {
  const reEmail = new RegExp(
    /^[-!#$%&'*+\0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
  );
  return reEmail.test(email);
};

export const validateData = data => {
  if (data.userName.length === 0) {
    return userNameError;
  }
  if (validateEmail(data.email) === false) {
    return emailError;
  }
  if (data.role.length === 0) {
    return roleError;
  }
  if (data.role === 'user' && data.customerNumber.length === 0) {
    return error;
  }

  return true;
};

export const prepareData = data => {
  let obj = {
    user: {
      fullname: data.userName,
      email: data.email,
      role: data.role,
    },
  };
  if (data.role === 'user') {
    obj.user.customer_number = data.customerNumber;
  }
  return obj;
};
