import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Mail, ShoppingCart, ArrowBack, Menu } from '@material-ui/icons';
import Sidebar from './Sidebar';
import Container from './Container';
import IconButton from './IconButton';
import ContactButton from './LargeButton';
import Config from '../../core/config/config.json';

const MainHeaderContainer = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  align-items: center;
  padding: 0 15px;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
`;
const LeftHeader = styled.div``;
const RightHeader = styled.div`
  display: flex;
  align-items: center;
  max-width: 70%;
`;
const CircleNumber = styled.span`
  font-size: 13px;
  background: #ed1c24;
  min-width: 15px;
  width: auto;
  position: absolute;
  left: 10px;
  top: 10px;
  height: 15px;
  border-radius: 100px;
  text-align: center;
  color: #fff;
  padding: 5px;
`;
const ContactTriggerButton = styled.button`
  padding: 5px 7px;
  border-radius: 3px;
  margin-right: 30px;
  border: none;
  background: #fff;
  &:hover {
    background: rgba(43, 61, 80, 0.15);
  }
`;
const ButtonClose = styled.button`
  color: #4c9bd6;
  width: auto;
  background: transparent;
  border: none;
  margin: 0px;
  height: auto;
  cursor: pointer;
  border-radius: 3px;
  outline: none;
  padding: 0;
  margin-right: 30px;
`;
const ContactContainer = styled.div`
  background-color: #fff;
  min-height: 150px;
  border-bottom: 1px solid rgba(43, 61, 80, 0.1);
  margin-bottom: 30px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;
const ContactTexts = styled.p`
  color: rgba(43, 61, 80, 0.8);
  font-size: 16px;
`;
const ImgHolder = styled.div`
  height: 25px;
  margin-left: 30px;
`;
const LogoImg = styled.img`
  width: 100%;
  vertical-align: middle;
`;

class Header extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showSidebar: false,
      showContact: false,
      btnText: '',
      btnUrl: '',
      currentUser: null,
    };
    this.toggleContact = this.toggleContact.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentWillMount() {
    const { currentUser } = this.props;
    if (this.props.search && this.props.search.length > 5) {
      const btnText = this.props.search
        .substring(this.props.search.lastIndexOf('?') + 1, this.props.search.lastIndexOf('='))
        .replace(new RegExp('%20'), ' ');
      const btnUrl = this.props.search.replace(new RegExp('.*='), '');
      this.setState({
        btnText,
        btnUrl,
        currentUser,
      });
    } else {
      this.setState({
        btnText: 'DESparts home',
        btnUrl: '/',
        currentUser,
      });
    }
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  toggleShow() {
    this.setState({
      showSidebar: !this.state.showSidebar,
    });
  }

  toggleContact() {
    this.setState({
      showContact: !this.state.showContact,
    });
  }

  handleClickOutside(event) {
    if (event.target.id === 'overlay') {
      this.setState({
        showSidebar: false,
      });
    }
  }

  goBack() {
    this.props.history.push(this.state.btnUrl);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showSidebar ? (
          <Sidebar currentUser={this.state.currentUser} tab={this.props.tab} />
        ) : (
          ''
        )}
        <MainHeaderContainer>
          <LeftHeader>
            {this.props.history ? (
              <IconButton
                onClick={this.goBack}
                Icon={ArrowBack}
                iconColor="#556473"
                iconSize="24px"
                iconAlign="middle"
                iconMargin="0 10px 0 0"
                value="Back"
                color="#556473"
                display="none"
                padding="0"
                background="transparent"
                border="none"
              />
            ) : (
              <IconButton
                display="none"
                Icon={Menu}
                iconAlign="middle"
                iconColor="rgba(43, 61, 80, 0.8)"
                iconMargin="0 10px 0 0"
                value="Navigation"
                color="rgba(43, 61, 80, 0.8)"
                background="transparent"
                iconSize="24px"
                border="none"
                onClick={this.toggleShow}
              />
            )}
          </LeftHeader>
          <RightHeader>
            <ContactTriggerButton onClick={this.toggleContact}>
              <Mail nativeColor="#556473" />
            </ContactTriggerButton>
            <Link to="/cart" style={{ position: 'relative' }}>
              <ShoppingCart nativeColor="#556473" />
              {this.props.totalItems === 0 ? (
                ''
              ) : (
                <CircleNumber>{this.props.totalItems}</CircleNumber>
              )}
            </Link>
            <ImgHolder>
              <Link to="/">
                <LogoImg src="/images/LogoDesParts.svg" alt="logo" />
              </Link>
            </ImgHolder>
          </RightHeader>
        </MainHeaderContainer>
        {this.state.showContact ? (
          <ContactContainer>
            <Container maxWidth="560px" padding="0 10px" alignItems="left" margin="auto">
              <ContactTexts>
                Please write us an e-mail at: <b>{Config.contact_email}</b>
              </ContactTexts>
              <ContactTexts>We will get back to you in the shortest time possible.</ContactTexts>
              <ButtonsContainer>
                <ButtonClose onClick={this.toggleContact}>CLOSE</ButtonClose>
                <a href={`mailto:${Config.contact_email}`}>
                  <ContactButton
                    color="#4c9bd6"
                    width="auto"
                    value="WRITE E-MAIL"
                    background="transparent"
                    border="none"
                    padding="0"
                  />
                </a>
              </ButtonsContainer>
            </Container>
          </ContactContainer>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

export default connect(state => ({ currentUser: state.login.currentUser }))(Header);
