import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const ModalTitle = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #000;
  margin: 0 0 30px 0;
`;
const Button = styled.button`
  color: ${props => (props.btnColor ? props.btnColor : '#5d9ad1')};
  background: none;
  border: none;
  margin: 0 0 0 40px;
  padding: 0;
  text-transform: uppercase;
`;
const ButtonContainer = styled.div`
  text-align: right;
`;
const Subtext = styled.p`
  color: ${props => (props.SubtextColor ? props.SubtextColor : '#000')};
`;
const customStyles = {
  content: {
    maxWidth: '90vw',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
  },
};

const ConfirmModal = ({
  title,
  label,
  text,
  closeModal,
  confirm,
  btnColor,
  SubtextColor,
  confirmText,
  cancelText,
}) => (
  <React.Fragment>
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={label}
      className="modal"
      overlayClassName="modalOverlay"
      ariaHideApp={false}
    >
      <div>
        <ModalTitle>{title}</ModalTitle>
        {text ? <Subtext SubtextColor={SubtextColor}>{text}</Subtext> : ''}
        <ButtonContainer>
          <Button onClick={closeModal}>{cancelText ? cancelText : 'Cancel'}</Button>
          <Button onClick={confirm} btnColor={btnColor}>
            {confirmText ? confirmText : 'Confirm'}
          </Button>
        </ButtonContainer>
      </div>
    </Modal>
  </React.Fragment>
);

export default ConfirmModal;
