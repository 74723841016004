import React from 'react';
import HeaderContainer from '../common/containers/headerContainer';
import BackToBtn from '../common/components/LargeButton';
import Container from '../common/components/Container';
import { Check } from '@material-ui/icons';
import styled from 'styled-components';
import { checkTokenIsStillValid } from '../core/userAuth/userAuth';

const TextTitle = styled.h3`
  color: rgba(43, 61, 80, 0.8);
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0;
  text-align: center;
`;
const TextBlock = styled.p`
  color: rgba(43, 61, 80, 0.8);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 40px;
`;
const buttonStyleActive = styled.button`
  color: #fff;
  width: 100%;
  background: #4c9bd6;
  border: 1px solid #478dc1;
  border-radius: 3px;
  padding: 10px;
`;
const IconStyles = {
  margin: '20px 0',
  fontSize: '82px',
  color: '#219653',
};

const CartSent = () => {
  checkTokenIsStillValid();

  return (
    <React.Fragment>
      <HeaderContainer btnText="cart" />
      <Container>
        <Check style={IconStyles} />
        <TextTitle>Your cart has been successfully sent.</TextTitle>
        <TextBlock>
          Your cart has been sent to DESMA for review. A DESMA employee will contact you soon to
          start an actual order.
        </TextBlock>
        <BackToBtn
          ButtonElement={buttonStyleActive}
          onSubmit={() => (window.location.href = '/')}
          value="Back to DESparts home"
        />
      </Container>
    </React.Fragment>
  );
};

export default CartSent;
