import request from 'superagent';
import SuperagentPromisePlugin from 'superagent-promise-plugin';
import { getCookie, deleteCookie } from '../../../core/cookie/cookie';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["fetchUsers", "createUser", "updateUser", "deleteUser", "fetchSingleUser"] }] */
class AdminService {
  async fetchUsers() {
    try {
      const response = await request
        // .get('https://sparepartordermanager.five.netsyno.com/api/get_all_users')
        .get(`/api/get_all_users`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async createUser(data) {
    try {
      const response = await request
        // .post('https://sparepartordermanager.five.netsyno.com/api/create_user')
        .post(`/api/create_user`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send(data);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async updateUser(data) {
    try {
      const response = await request
        // .post('https://sparepartordermanager.five.netsyno.com/api/update_user')
        .post(`/api/update_user`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send(data);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async deleteUser(data) {
    try {
      const response = await request
        // .post('https://sparepartordermanager.five.netsyno.com/api/delete_user')
        .post(`/api/delete_user`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`)
        .send(data);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      throw e;
    }
  }

  async fetchSingleUser() {
    try {
      const response = await request
        // .get(
        //   `https://sparepartordermanager.five.netsyno.com/api/retrieve_user_by_token/${getCookie()}`
        // )
        .get(`/api/retrieve_user_by_token/${getCookie()}`)
        .use(SuperagentPromisePlugin)
        .set('Content-Type', 'application/json')
        .set('Authorization', `Token ${getCookie()}`);

      const responseJson = await response.body;
      return responseJson;
    } catch (e) {
      deleteCookie();
      window.location.href = '/';
      throw e;
    }
  }
}

export default new AdminService();
