/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

import PartPage from './PartPage';
import CartPage from './CartPage';
import CartSent from './CartSent';
import LoginPage from './LoginPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';
import PartsListPage from './PartsListPage';
import CartListPage from './CartListPage';
import CartListDetailsPage from './CartListDetailsPage';
import FavoritePartsPage from './FavoritePartsPage';
import TermsPage from './TermsPage';
import AdminListPage from './AdminListPage';
import AdminDetailsPage from './AdminDetailsPage';
import AdminCreatePage from './AdminCreatePage';
import scanQRComponent from '../modules/parts/components/scanQRComponent';
import UserAuth from '../core/userAuth/userAuth';
import SuccessSend from './SuccessSend';
import ResetSuccess from './ResetSuccess';
import AdminCreateSuccess from './AdminCreateSuccess';
import NotFound from '../core/components/NotFound';

const NavigationPage = ({ token, currentUser, loading }) => (
  <React.Fragment>
    <UserAuth>
      <BrowserRouter>
        {loading ? (
          ''
        ) : token ? (
          <React.Fragment>
            {currentUser && currentUser.accepted_terms_and_privacy_policy ? (
              <React.Fragment>
                <Switch>
                  <Route exact path="/" component={PartsListPage} />
                  <Route exact path="/scan-qr" component={scanQRComponent} />
                  <Route path="/item/:itemId" component={PartPage} />
                  <Route exact path="/cart" component={CartPage} />
                  <Route exact path="/savedcarts" component={CartListPage} />
                  <Route path="/savedcarts/:id" component={CartListDetailsPage} />
                  <Route exact path="/orders" component={CartListPage} />
                  <Route path="/orders/:id" component={CartListDetailsPage} />
                  <Route path="/cart/sent" component={CartSent} />
                  <Route path="/cart/success" component={SuccessSend} />
                  <Route path="/favorites" component={FavoritePartsPage} />
                  <Route exact path="/terms" component={TermsPage} />
                  <Route path="/login" render={() => <Redirect to="/" />} />
                  {currentUser.is_admin ? (
                    <React.Fragment>
                      <Route exact path="/admin" component={AdminListPage} />
                      <Route path="/admin/account/:id" component={AdminDetailsPage} />
                      <Route exact path="/admin/usercreate" component={AdminCreatePage} />
                      <Route path="/admin/usercreate/success" component={AdminCreateSuccess} />
                      <Route exact path="/forgotten-password" component={ForgotPasswordPage} />
                      <Route exact path="/reset/success" component={ResetSuccess} />
                      <Route exact path="/reset/:uidb64/:token" component={ResetPasswordPage} />
                      <Route exact path="/activate/:uidb64/:token" component={ResetPasswordPage} />
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  <Route render={({ location }) => <NotFound pathname={location.pathname} />} />
                </Switch>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Route exact path="*" component={TermsPage} />
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Switch>
              <Route exact path="/forgotten-password" component={ForgotPasswordPage} />
              <Route exact path="/reset/success" component={ResetSuccess} />
              <Route exact path="/reset/:uidb64/:token" component={ResetPasswordPage} />
              <Route exact path="/activate/:uidb64/:token" component={ResetPasswordPage} />
              <Route path="*" component={LoginPage} />
            </Switch>
          </React.Fragment>
        )}
      </BrowserRouter>
    </UserAuth>
  </React.Fragment>
);

const mapStateToProps = state => ({
  token: state.login.token,
  loading: state.login.loading,
  currentUser: state.login.currentUser,
});

export default connect(mapStateToProps)(NavigationPage);
